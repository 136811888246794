@import '../../../../../../../resources/scss/breakpoints';

.subnav-toggle {
  display: none;
}

@include mq(tablet) {
  .mobile-subnav-trigger {
    display: none;
  }
}
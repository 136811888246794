.article-list {

	.article-list__list {
		display:flex;
		flex-wrap: wrap;
		justify-content: center;
		//max-width: 1170px;
		width: 100%;
	}
	
}
.ratings-app {
    .stars {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0 10px 0 0;
        padding: 0;
        width: auto;
        &::before {
            background: none;
        }
        > li {
            line-height: 0;

            &:not(:first-child) {
                padding-left: 2px;
            }
        }
    }
    .average-rating {
        display: flex;
        align-items: center;
        padding: 0 3px 0 0;
    }

    .review-count {
        display: flex;
        align-items: center;
    }
}

.cmp-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    i,
    span {
        margin: 0 .5em 0 0;
        font-size: 1.25rem;
    }

    &.icon-right {
        flex-direction: row-reverse;

        i,
        span {
            margin: 0 0 0 .5em;
        }
    }

    &.icon-top {
        flex-direction: column;
        padding: 1em;
        height: auto;

        i,
        span {
            margin: 0 0 .3em 0;
        }
    }

    &.icon-bottom {
        flex-direction: column-reverse;
        padding: 1em;
        height: auto;

        i,
        span {
            margin: .3em 0 0 0;
        }
    }

    &.icon-large {
        i,
        span {
            font-size: 1.5rem;
        }
    }

    &.icon-small {
        i,
        span {
            font-size: 1rem;
        }
    }
}

.button-tdg {
    background-color: #434343 !important;
}

.button-tdg-container {
    display: none;
    margin-bottom: 9pt;
    margin-top: 9pt;
    @media screen and (max-width: 1023px) {
        display: block;
    }
}


.menu-utility-container.full .button-tdg-container {
    display: none !important;
}
.no-scroll {
    overflow: hidden;
}


// TODO: check if this is only used in nav, if so it should be scoped as such
.page-overlay {
    background-color: #434343;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.6;
    z-index: 100; // needed to overlay sticky in-page-nav but be at/below header nav
    min-height: 100vh;
}
@import '../../../../../../../resources/scss/global.scss';

// force content card in grid/column to flex column
// need to revisit/discuss best "default" for these cards
// and should this override (if necessary) be in shared or site theme
/*
**** shouldnt need this anymore *****
.grid > .content-card, .grid-column > .content-card, .column > .content-card {

    flex-direction: column;
    overflow: visible;

    >* { // was: > div
        width: 100%;
    }
}
*/

// content card: base default layout rules
.content-card {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden; // not sure this should be here in shared

    >div { // should probably target via bem
        width: 50%;
        box-sizing: border-box;

        @include mq(lt-tablet) {
            width: 100%;
        }
    }

    >.image,
    >.cmp-video {
        img {
            width: 100%;
            display: block;
        }
    }

    >.content-card__text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        >.content-card--visit-reward-center{
            padding: 0px 20px 0px 20px;
        }
    }

    .cmp-video-wrapper {
        max-width: none;
    }

    @include mq(lt-tablet) {
        flex-direction: column;
    }

}


.content-card--media-right {
    flex-direction: row-reverse;

    @include mq(lt-tablet) {
        flex-direction: column;
    }

}


.content-card--media-top {
    flex-direction: column;

    >div {
        width: 100%;
    }
}


.content-card--media-bottom {
    flex-direction: column-reverse;

    >div {
        width: 100%;
    }

    >.content-card__text {
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 40px;

        @include mq(lt-tablet) {
            flex-direction: column;
        }

        > div {
            width: 50%;

            @include mq(lt-tablet) {
                width: 100%;
            }
        }
    }
}

.dealer-use-current--error {
    display: none;
    margin: 35px 0 20px 5px;

     h4 {
      font-size: 14px;
      font-weight: 400;
     }

     p{
      font-size: 14px;
      color: #757575;
     }

     &:before{
      content: "";
      background-image: url('data:image/svg+xml,<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Group 13932"><g id="Group 13931"><g id="Group 13930"><g id="Group 13929"><circle id="Ellipse 21" cx="9.5" cy="9.5" r="9.5" fill="%23951200"/><path id="!" d="M9.38295 15C9.00145 15 8.67293 14.873 8.3974 14.6191C8.13247 14.355 8 14.0402 8 13.6745C8 13.3089 8.13247 12.9991 8.3974 12.7452C8.67293 12.4912 9.00145 12.3643 9.38295 12.3643C9.76445 12.3643 10.0877 12.4912 10.3526 12.7452C10.6175 12.9991 10.75 13.3089 10.75 13.6745C10.75 14.0402 10.6175 14.355 10.3526 14.6191C10.0877 14.873 9.76445 15 9.38295 15ZM8.30202 7.1385V4H10.448V7.1385L10.2254 11.1607H8.52457L8.30202 7.1385Z" fill="white"/></g></g></g></g></svg>');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 19px;
      height: 19px;
      left: -6px;
      position: relative;
      right: 0;
      top: -2px;
      margin-right: 5px;
     }

     @include mq(lt-phone) {
      margin: 20px 0 20px 5px;
      &:before{
        top:0px;
        margin-right: 0px;
      }
    }
  }
/*
	Enterprise Shard Data Privacy Component/Experience Fragments
*/

@import '../../../../../../../resources/scss/breakpoints';

.optoutform {
    background-color: white;
    margin-top: 40px;

    .opt-out-form-container {

        .opt-out-title {
            width: 90%;
            font-size: 42px;

            @include mq(lt-phablet) {
                font-size: 26px;
            }
        }

        .opt-out-state {
            background-color: white;
            padding: 0;
            width: 90%;
            margin: 0 auto;

            .opt-out-state-radio {
                width: 100%;
                max-width: unset;
            }

            .opt-out-state-radio__title {
                text-transform: none;
                color: black;
            }
        }

        .opt-out-info {
            padding: 0 0 10px;
            max-width: unset;
            width: 100%;

            p {
                width: 100%;

                @include mq(lt-tablet) {
                    width: 100%;
                }
            }
        }

        .opt-out-privacy-section {
            background-color: white;
            padding: 0 0 30px 0;
            width: 100%;
            margin: 0 auto;
            min-height: unset;

            .opt-out-form-message {
                max-width: none;
            }
        }

        .opt-out-form-section {
            background-color: white;
            padding: 0 0 10px;
            max-width: unset;
            width: 100%;

            .opt-out-form {
                max-width: unset;

                .opt-out-form__subtitle {
                    margin: 0 0 20px;
                }
            }
        }

        .opt-out-form-information-section {
            background-color: white;
            padding: 0;
            width: 90%;
            margin: 0 auto;

            .opt-out-form {

                .required {
                    margin: 0 0 20px 0;
                    font-size: 0.75em;
                }

                .opt-out-form__input {
                    margin-bottom: 20px;
                }

                label {
                    line-height: 2em;
                }

                input[type=checkbox] {
                    transform: scale(1.5);
                    margin: 11px 12px 12px 5px;
                }
            }
        }
    }
}

// ReCaptcha Badge Introduced from Data Privacy Component ...
// Placement is right where Chat/Contact Widget Icon Sits ...
.grecaptcha-badge {
    display: none !important;
}

@import '../../../../../../../../resources/scss/breakpoints'; // ui.frontend/src/main/webpack/resources/scss/_breakpoints.scss
.rm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000000, 0.65);
    z-index: 10001;
    display: flex;
}

.rm-container {
    // height: 100%;
    background-color: white;
    outline: none;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    margin-top: 2%;
    max-height: calc(100vh - 4%);

    @include mq(lt-tablet) {
        margin-top: 1%;
        width: auto;
        height: auto;
        max-height: calc(100vh - 2%);
        padding: 35px;
    }

    @include mq(lt-phablet) {
        height: 100%;
        min-width: 100vw;
    }

}

.rm-content {

}

.rm-header {
    line-height: 20px;
    height: 20px;
    > * {
        cursor: pointer;
    }

    p {
        color: transparent;
        position: absolute;
        top: 15px;
        right: 15px;
        width: 20px;
        height: 17px;
        margin: 0;
        line-height: 0;
        font-size: 0; //25px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 7px;
            right: 0;
            width: 20px;
            height: 2.6px;
            background-color: #4a4a4a;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }
}

.rm-open-body .rm-modal {
    max-width: 830px;
    margin: 0 auto;
    min-height: 300px;

}

@media print {
    body.rm-open-body {
        overflow: visible !important;
        #wrapper > header,
        #wrapper > footer.site-footer,
        #wrapper > .coral-modal,
        #wrapper > #main {
            display: none;
            height: 0;
            width: 0;
        }
        .rm-modal,
        .rm-modal > .rm-overlay,
        .rm-modal > .rm-overlay > .rm-container {
            display: block;
            height: auto;
            max-height: auto;
            overflow: visible;
            overflow-y: visible;
            position: relative;
        }
        .rm-content {
            display: block;
            width: 100%;
            height: auto;
            max-height: auto;
            overflow: visible;
            overflow-y: visible;
            position: relative;
        }
    }
}

@import 'src/main/webpack/resources/scss/breakpoints';

.fsd-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 0 15px 0;
    background-color: whitesmoke;
    border: 1px solid gray;
    opacity: 0;
    height: auto;

    @keyframes fsdHeight {
        0% {
            max-height: 0;
        }

        100% {
            max-height: 500px;
        }
    }

    @keyframes fsdFade {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @include mq(lt-tablet) {
        margin-right: 10px;
        flex: 1 0 320px;

        .store-cards-list-view & {
            margin: 0 0 15px 0;
            flex: 1 0 240px;
        }
    }

    &__banner {
        background: black;
        width: 100%;
        padding: 5px;
        color: white;
        text-align: center;
        font-size: 14px;
    }

    &__logo {
        background-image: url('https://assets.bridgestonetire.com/content/dam/consumer/bst/shared/logos/logo-fsd.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        width: 100%;
        height: 55px;
        margin: 20px 0;
    }

    hr {
        background-color: gray;
        width: 90%;
        margin: 0 0 10px 0;
    }

    p {
        font-size: 14px;
    }

    .button {
        flex: none;
        margin: 0 5px;
        width: 44%;
        cursor: pointer;
    }

    &__link {
        position: relative;
        margin: 10px 0 0px;
        font-size: 12px;
        width: fit-content;
        display: block;
        text-align: center;
        text-decoration: none;
    }

    .dynamic-top-container & {

        @include mq(lt-tablet) {
            margin-right: 0;
            flex: 1;
        }

        @include mq(lt-phablet) {
            margin-right: 10px;
            flex: 1 0 320px;
        }

    }
}
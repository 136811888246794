@import "../../../../../../../resources/scss/breakpoints";

$disabledColor: #b3b3b3;

.tire-decision-guide {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 16px;
  }

  h1 {
    font-size: 32px;
  }

  h3 {
    font-size: 18px;
  }

  .button, a{
    cursor: pointer;
  }

  .skipToResultsBtn{
    text-transform: uppercase;
    text-decoration: none;
    font-style: italic;
    border-bottom: 3px dashed #e42300;
  }

  .tire-decision-guide__steps {
    height: auto;
    width: 600px;

    .tire-decision-guide__page {
      display: none;

      &.active {
        display: flex;
        flex-direction: column;
        place-items: center;
      }

      .location-search-tdg {
        margin-bottom: 200px;
        align-self: flex-start;

        .use-current{
          margin-top: 15px;
        }

        input {
          width: 625px;
          margin-bottom: 12px;

        }

        button {
          display: none;

          &.location-loading {
            display: inline;
            cursor: not-allowed;
            pointer-events: none;
            border-radius: 0;
            padding: 0;
          }

          &.location-loading,
          &.location-loading:before,
          &.location-loading:after {
            background: red;
            -webkit-animation: load1 1s infinite ease-in-out;
            animation: load1 1s infinite ease-in-out;
          }

          &.location-loading {
            color: red;
            text-indent: -9999em;
            margin: 0 0 0 26px;
            position: absolute;
            font-size: 9px;
            width: 9px;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
          }

          &.location-loading:before,
          &.location-loading:after {
            position: absolute;
            width: 9px;
            top: 15px;
            content: "";
          }

          &.location-loading:before {
            left: -15px;
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
          }

          &.location-loading:after {
            right: -15px;
          }

          @-webkit-keyframes load1 {
            0%,
            80%,
            100% {
              box-shadow: 0 0;
              height: 1.5em;
            }
            40% {
              box-shadow: 0 -1.5em;
              height: 2.5em;
            }
          }

          @keyframes load1 {
            0%,
            80%,
            100% {
              box-shadow: 0 0;
              height: 1.5em;
            }
            40% {
              box-shadow: 0 -1.5em;
              height: 2.5em;
            }
          }
        }

        &.fade-in {
            opacity: 1;
            transition: all 300ms ease-in-out;
        }

        p:not(.use-current) {
            width: 100%;
            border: 0;
            height: auto;
            margin: 0;
            padding: 0 0 1rem 0;

            &.error {
                display: none;
                font-size: 12px;
                color: red;

                &.show-error {
                    display: block;
                }
            }
        }
      }
    }

    .tire-decision-guide--link-container-right {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      @include mq(lt-tablet) {
        justify-content: center;
      }
    }

    .tire-decision-guide--link-container {
      display: flex;
      justify-content: space-between;
      align-items: end; //center
      width: 648px;
    }

    .tire-decision-guide--link {
      &.findTireSizeBtn, &.learnMoreBtn{
        display: inline-block;
        position: relative;
        padding-left: 25px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1px;
        text-align: left;
        &:before {
          content: "";
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><circle cx="8.5" cy="8.5" r="8.5" fill="%23005EB8"/><path d="M9.26591 5.3588C9.26591 5.40611 9.24226 5.42976 9.19495 5.42976H7.52747C7.492 5.42976 7.45652 5.40611 7.45652 5.3588V3.93967C7.45652 3.89237 7.48017 3.86872 7.52747 3.86872H9.19495C9.23043 3.86872 9.26591 3.89237 9.26591 3.93967V5.3588ZM9.2186 12.6673C9.2186 12.7028 9.19495 12.7383 9.14765 12.7383H7.57478C7.52747 12.7383 7.50382 12.7146 7.50382 12.6673V6.51776C7.50382 6.47045 7.52747 6.4468 7.57478 6.4468H9.14765C9.19495 6.4468 9.2186 6.47045 9.2186 6.51776V12.6673Z" fill="white"/></svg>');
          background-repeat: no-repeat;
          background-size: 17px;
          bottom: 0;
          display: block;
          height: 17px;
          left: -22px;
          position: relative;
          right: 0;
          top: 16px;
          width: 17px;
        }
      }
    }

    .findTireSizeBtn{
      top: 30px;
      left: -82px;
    }
  }

  .tire-decision-guide__footer {
    width: 100%;
    height: 110px;
    max-width: 1440px;
    position: sticky;
    bottom: 0px;
    // z-index: 1000;
    justify-items: center;
    align-content: center;
    background: linear-gradient(to top, rgb(255 255 255) 0%, rgb(255 255 255) 81%, rgba(0, 0, 0, 0) 100%);

    .tire-decision-guide__footer--progress svg {
      width: 16%;
    }

    div:nth-of-type(1) {
      height: 20px;
      display: flex;
      width: 91%;
      justify-content: center;
      gap: 5px;
    }

    div:nth-of-type(2) {
      display: flex;
      justify-content: space-between;
      width: 84%;
      margin: auto;
    }

    &:has(.getStartedBtn:not(.hide)){
      div:nth-of-type(2) {
        justify-content: end;
      }
    }

    &.hide,
    .hide {
      display: none;
    }


    .getStartedBtn,
    .nextBtn {
      font-family: 'avenir-medium';
      padding: 17px 36px 14px 36px;
      font-size: 16px;
      line-height: 16px;
      border-radius: 4px;
      text-transform: none;
      font-weight: bolder;
      letter-spacing: 0px;
      height: 52px;

      @include mq(phone) {
        padding: 17px 49px 14px 49px;
      }
    }

    .prevBtn {
      background: none;
      color: black;
      text-transform: none;
      text-decoration: underline;
      font-size: 18px;
      cursor: pointer;
      font-weight: bold;
      font-family: unset;
      letter-spacing: normal;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .tire-decision_guide__landing {
    align-items: center;
    //padding: 20px;
    //padding-top: 68px;
    display: flex;
    width: 80vw;
    max-width: 1095px;
    height: 516px;

    .header-wrapper {
      margin-bottom: 6vw;

      .landing-header {
        font-size: 42px;
        font-style: italic;
        font-weight: 700;
        letter-spacing: 1.2px;
        text-align: left;
      }
    }
  }

  .tire-decision_guide__findtire {
    width: 1096px;
  }

  .tire-decision-guide__results {
    width: 1089px;
    height: 994px;
  }

  .tire-decision-guide__container {
    padding: 0;
    width: 750px;
  }
  .tire-decision-guide__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;

    .tire-decision-guide__item {
      padding: 0px;
      margin: 10px;
      position: relative;

      input {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        appearance: none;
        cursor: pointer;

        &:checked + label {
          background: #eeeeee;
          border-color: #656565;
        }

        &:disabled + label {
          border-color: $disabledColor;

          svg path {
            fill: $disabledColor;
          }

          .tire-decision-guide__p {
            color: $disabledColor;
          }

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            cursor: not-allowed;
          }
        }
      }

      .tire-decision-guide__p {
        font-size: 18px;
        font-weight: bold;
        line-height: 23px;
        padding-top: 8px;
      }

      label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        border: 1px solid $disabledColor;
        width: 203px;
        height: 113px;
        border-radius: 4px;
        padding: 8px;
      }

      .tire-decision-guide__svg{
        padding-top: 6px;
      }

      .tire-decision-guide__label-align {
        display: block;
      }

      #s1-c3 + label .tire-decision-guide__p {
        padding-top: 12px;
    }


      #s1-c5 + label .tire-decision-guide__p {
          padding-top: 13px;
      }
    }
  }

  .tire-decision-guide__list__wide {
    list-style: none;
    padding: 0;

    .tire-decision-guide__wide {
      position: relative;
      height: 148px;
      width: 648px;
      padding: 0;
      margin: 20px;

      input {
        position: absolute;
        top: 32px;
        right: 24px;
        accent-color: #e42300;

        &:checked + label {
          background: #eeeeee;
          border-color: #656565;
        }
      }

      label {
        display: block;
        cursor: pointer;
        border: 1px solid $disabledColor;
        border-radius: 4px;
        padding: 24px;
        height: inherit;
        width: inherit;
      }
    }

    .tire-decision-guide__wide__svg {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    .tire-decision-guide__wide__psubheader{
      padding-top: 20px;
    }

  }

  .header-tdg {
    text-align: center;
  }

  .subheader-tdg {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    padding-bottom: 30px;
  }

  .options-wrapper {
    width: 536px;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .landing-options {
      list-style: none;

      .landing-options__list {
        display: flex;
        height: 84px;
        margin-bottom: 64px;
      }

      .svg-tdg {
        width: 48px;
        height: 48px;
        display: flex;
      }

      .text-verticle {
        width: 426px;
        margin-left: 43px;

        .text-verticle__header {
          font-size: 24px;
          font-style: italic;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0.74px;
          margin-bottom: 8px;
        }

        .text-verticle__subheader {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }

  .tsr-profile__selected {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .cmp-tabs__tab{
    text-wrap: auto;
  }

  .selected-info {
    width: 307px;
    border: 1px solid #005EB8;
    background: #E4F0F9;
    display: flex;
    padding: 10px;
    border-radius: 4px;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    p {
      font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: 14.72px;
    letter-spacing: 0.47999998927116394px;
    text-align: left;
    margin: 0 0 0 15px;
    }
    &.disabled {
      pointer-events: none;
      width: 247px;
      margin-left: 5px;
      height: auto;
    }
    a.disabled p {
      margin-left: 0 0 0 15px;
    }
    .vehicle-info{
      width: 225px;
    }
  }
  .edit-vehicle {
    margin-right: 15px;
    margin-top: 3px;
  }
  .tire-search-results {
    background-color: #f0f0f0;
    height: 994px;
  }
  .tdg-matches__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding: 10px 0;
    margin: 0 80px;
  }

  .tdg-card {
    width: 296px;
    height: 397px;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    .tdg-card-header {
      font-size: 24px;
      font-style: italic;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.74px;
      text-align: center;
      margin-bottom: 16px;
      padding: 10px;
    }

    .tdg-card-mileage__container {
      display: flex;
      justify-content: center;
    }

    .tdg-card-mileage {
      font-size: 14px;
      font-weight: 800;
      text-align: left;
      margin-left: 10px;
    }

    .tdg-card-description {
      font-size: 14px;
      font-weight: 350;
      line-height: 18px;
      text-align: center;
      width: 175px;
      margin-bottom: 10px;
    }

    .tdg-image {
      height: 114px;
    }

    .tdg-warranty-price {
      border: none;

      p, span {
        font-size: 24px;
        font-weight: 900;
        line-height: 24px;
        text-align: left;
        color: black;
      }
      sup {
        font-size: 14px;
        font-weight: 800;
        line-height: 18px;
        text-align: left;
      }

      .total-for-four {
        font-size: 14px;
        /* width: 134px; */
        line-height: 18px;
        color: #757575;
      }
    }
    .tdg-locate-button {
      border-left: none;
      padding: 15px 0;
      margin-left: 0;

    }

  }
  .tire-card-link {
    text-decoration: none;
    color: inherit;
  }

  .tire-card-link:hover {
    text-decoration: none;
    color: inherit;
  }
  .tdg-form__container {
    display: flex;
    width: 1057px;
    height: 174px;
    top: 737px;
    border: #cccccc solid 1px;
    background-color: #ffffff;
    justify-content: space-between;
    padding: 20px;
    border-radius: 4px;
    margin: 0 auto;

    .tdg-form__header {
      width: 95%;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
    }
    .tdg-form__subheader {
      width: 400px;
      height: 66px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }

    &.hide {
      display: none;
    }
  }

  .tdg-form {
    form {
      display: flex;
      width: 525px;
      height: 45px;
      margin-top: 34px;
    }

    #tdg-email-button{
      margin-left: 7px;
      min-height: unset;
      height: 45px;
    }
  }
  // Desktop email form
    .email-success-container {
      display: flex;
      width: 524px;
      height: 64px;
      border-radius: 4px;
      border: 1px solid #005EB8;
      padding: 20px;
      background: #E4F0F9;
  }

  .email-success-text {
    margin-left: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .tdg-email-change {
    border: none;
    background: none;
    border-bottom: dashed 1px red;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
    margin-top: 13px;
    padding: 0;
    cursor: pointer;
  }

  .results-button__container {
    text-align: center;
    margin-top: 60px;

    .tdg-button-results {
      margin-bottom: 31px;
      .tdg-results-all {
        width: 241px;
        height: 52px;
      }
    }

    .restart-search {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      text-decoration: underline;
    }
  }

  .no-results {
    display: flex;
    border: 1px solid #cccccc;
    width: 1057px;
    height: 461px;
    gap: 0px;
    border-radius: 4px;
    padding: 80px 0px;
    margin-left: 110px;
    margin-right: 110px;

    .no-results-left {
      margin-left: 75px;
      h1 {
        width: 427px;
        height: 52px;
        font-size: 21px;
        font-style: italic;
        font-weight: 700;
        line-height: 25.77px;
        text-align: left;
      }

      p {
        width: 313px;
        height: 72px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }

      .no-results-button {
        width: 355px;
        padding: 15px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        margin: 20px 0;
        text-decoration: none;
        text-transform: uppercase;

        &.no-results-reset {
          background: black;
          color: white;
        }

        &.no-results-all {
          background: white;
          color: black;
          border: 1px solid #cccccc;
        }
      }

    }

    .no-results-right {
      justify-content: flex-end;
      display: flex;
      width: 100%;
      margin-right: 90px;
    }
  }

  .tire-decision_guide__findtire{
    .tabs{
      border: 1px solid $disabledColor;
      border-radius: 10px;
      max-width: 980px;
      margin: auto;
    }

    .cmp-tabs__tablist{
      padding: 15px 10px 16px;

      .cmp-tabs__tab {
        padding: 8px;
        font-size: 12px;
      }

      [class^="icon-consumer-"] {
        color: #e42300;
      }
    }

    .select__options{
      overflow-y: scroll;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;
    }

    .vehicle-selector-fitment__select, .vehicle-selector-size__select{
      height: 367px;

      .select__heading{
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    .findTireSizeBtn{
      top: 46px;
    }
  }

  .cmp-tire-card-recommended p{
    color: #000;
  }

  .tdg-tire-card .cmp-tire-card__checkbox { //Hide Compare for 12/11 release
    display: flex;
  }

  .store-locator__cf {
    display: none;
  }

  .use-current--error {
    display: none;
    margin: 35px 0 20px 5px;

     h4 {
      font-size: 14px;
      font-weight: 400;
     }

     p{
      font-size: 14px;
      color: #757575;
     }

     &:before{
      content: "";
      background-image: url('data:image/svg+xml,<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Group 13932"><g id="Group 13931"><g id="Group 13930"><g id="Group 13929"><circle id="Ellipse 21" cx="9.5" cy="9.5" r="9.5" fill="%23951200"/><path id="!" d="M9.38295 15C9.00145 15 8.67293 14.873 8.3974 14.6191C8.13247 14.355 8 14.0402 8 13.6745C8 13.3089 8.13247 12.9991 8.3974 12.7452C8.67293 12.4912 9.00145 12.3643 9.38295 12.3643C9.76445 12.3643 10.0877 12.4912 10.3526 12.7452C10.6175 12.9991 10.75 13.3089 10.75 13.6745C10.75 14.0402 10.6175 14.355 10.3526 14.6191C10.0877 14.873 9.76445 15 9.38295 15ZM8.30202 7.1385V4H10.448V7.1385L10.2254 11.1607H8.52457L8.30202 7.1385Z" fill="white"/></g></g></g></g></svg>');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 19px;
      height: 19px;
      left: -6px;
      position: relative;
      right: 0;
      top: -2px;
      margin-right: 5px;
     }

     @include mq(lt-phone) {
      margin: 20px 0 20px 5px;
      &:before{
        top:0px;
        margin-right: 0px;
      }
    }
  }
  //
  //
  // MOBILE LAYOUT, phablet it 768px
  //
  //

  @include mq(lt-phablet) {
    //Add mobile css here, mobile version actually kicks in at 768px for the much of the site
    .tire-decision-guide__steps {
      width: 335px;
      height: auto; //485
    }

    .results-button__container {
      padding-bottom: 20px;
    }

    .tsr-profile__selected {
      flex-direction: column;
      align-items: flex-start;

      h1{
        text-align: left;
      }
    }

    .tire-decision-guide__steps [data-page="3"]{
      height: 325px;
    }

    .tire-decision_guide__landing {
      display: block;
      width: 335px;
      padding: 0;

      .header-wrapper {
        height: 64px;

        .landing-header {
          text-align: center;
          width: 332px;
          line-height: 34px;
        }
      }
    }

    .tire-decision-guide__steps .tire-decision-guide__page{
      .location-search-tdg{
        margin-left: 10px;
        input {
          width: 80vw;
        }
      }
    }

    .tire-decision-guide__results {
      width: 100%;
      height: auto;

      h1 {
        font-size: 25px;
      }

      .tire-search-results {
        height: auto;
      }
    }

    .tdg-card {
      margin-right: 10px;
    }

    .options-wrapper {
      .landing-options {
        list-style: none;
        padding: 0;

        .landing-options__list {
          display: flex;
          height: auto;
          margin: 36px 0 -26px 0;
          width: 374px;
        }

        .svg-tdg {
          width: 32px;
          height: 32px;
        }

        .text-verticle {
          width: 375px;
          margin-left: 38px;
          padding-left: 0;

          .text-verticle__header {
            font-size: 16px;
            margin-bottom: 8px;
            line-height: 20px;
            letter-spacing: 1.33px;
          }

          .text-verticle__subheader {
            font-size: 16px;
            line-height: 22px;
            width: 240px;
          }
        }
      }
    }

    .tire-decision-guide__container {
      padding: 0;
      width: 100%;
    }

    .tire-decision-guide__steps{
      .tire-decision-guide--link-container{
        width: 98%;

        .learnMoreBtn{
          padding-right: 4vw;
        }
      }
    }

    .tire-decision-guide__list {
      padding: 0;

      .tire-decision-guide__item {
        width: 46%;
        margin: 5px;
        cursor: pointer;

        label {
          width: 100%;
        }

        .tire-decision-guide__p{
          font-size: 16px;
        }
      }

      .tire-decision-guide__svg {
        padding-top: 5px;

        svg{
          width: 24px;
          height: 24px;
        }
      }
    }

    .tire-decision-guide__list__wide {
      list-style: none;
      padding: 0;

      .tire-decision-guide__wide {
        height: auto;
        width: 100%;
        border-radius: 4px;
        padding: 11px;
        margin: 0px;

        label{
          min-height: 190px;
        }
      }

      .tire-decision-guide__wide__psubheader {
        width: 100%;
        height: 54px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }
    }

    .tdg-form__container {
      width: 335px;
      height: 267px;
      display: block;
      .tdg-form__header {
        font-size: 19px;
      }

      .tdg-form__subheader {
        font-size: 13px;
        line-height: 18px;
        width: 275px;
        height: 83px;
      }
    }
    .tdg-matches__cards {
      margin: 0 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .cmp-tire-card-recommended{
        margin: 10px;
      }
    }

    .tdg-form {
      form {
        width: 297px;
        height: 42px;
      }
    }

    .email-success-container {
      width: 295px;
      height: auto;
      padding: 10px;

      svg {
        margin: 20px 0 0px 10px;
      }
    }

    .email-success-text {
      font-size: 14px;
      margin-top: 10px;
    }

    // mobile no resutls
    .no-results {
      width: 335px;
      height: 482px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin: 0 20px;
      .no-results-left {
        margin-left: 0;
        width: 335px;
        h1 {
          height: 48px;
          width: 260px;
          margin-left: 40px;
          font-size: 16px;
          margin-right: 40px;
          margin-top: 38px;
          line-height: 17px;
        }

        p {
          width: 258px;
          height: 54px;
          margin-left: 38px;
          margin-right: 38px;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
        }

        .no-results-button {
          margin: 38px 20px -30px;
          font-size: 14px;
          width: 297.57px;
        }
      }

      .no-results-right {
        display: flex;
        justify-content: center;
        height: 112px;
        margin-right: 0;
        svg {
          width: 112px;
          height: 112px;
        }
      }
    }

    .tire-decision_guide__findtire .findTireSizeBtn{
      top: -12px;
    }
  }

  //
  //
  // MOBILE LAYOUT, tablet
  // 1024px
  //

  @include mq(lt-tablet) {

    .tire-decision_guide__landing {
      .header-wrapper {
        .landing-header {
          font-size: 32px;
        }
      }
    }

    .tire-decision-guide__steps{
      width: 90vw;
    }

    .location-search-tdg{
      margin-left: 152px;
    }

    .tire-decision_guide__findtire {
      color: #000;
      width: 100%;

      .tabs{
        width: 100%;
        margin: 0px;

        .cmp-tabs__tab {
          font-size: 10px;
        }
      }

      .findTireSizeBtn{
        top: -11px;
        left: -7px;
      }

      .vehicle-selector-fitment, .vehicle-selector-size{
        .vehicle-selector-fitment__display, .vehicle-selector-size__display{
          //margin-left: -20px;
          width: 100%;
          border-radius: 10px;
          margin-bottom: 0px;
          position: sticky;
        }
      }

      .vehicle-selector-fitment__select, .vehicle-selector-size__select{
        padding: 3px;

        &.active .fitment__message{
          margin: 0px;
        }
      }
    }
  }



// move this to bst css once bst css is ready

  .tire-comparison {
    top: 70px;
    height: 100%;
    left: 0;

    // hiding ratings for now
    .cmp-tire-card-compare__rating.tire-rating-small {
        display: none;
    }

    .compare-switch {
      display: none;
    }
  }
  .cmp-tire-comparison {

      @include mq(lt-phablet) {
          margin-bottom: 20px !important;
      }

      &__flyout {

          @include mq(lt-phablet) {
              flex-wrap: wrap !important;
              width: 100% !important;
              padding: 1em 0 !important;
              justify-content: space-around !important;
          }

          a.button {
              @include mq(lt-phablet) {
                  width: 90% !important;
              }
          }

          .flyout__card {

              &__image {

                  &__placeholder {
                      background-color: #eeeeee !important;
                  }

              }

              &__name {
                  font-size: 16px !important;
                  text-align: center !important;
              }

          }

          p {
              font-size: 16px !important;
          }

      }

      &__col {
          position: relative !important;
          border: 1px SOLID #C6C6C6 !important;
          border-radius: 16px !important;

          .performance {

              &__rating-bar {
                  background-color: #C6C6C6 !important;
              }

              &__rating-bar-inner {
                  background-color: #E42300 !important;
              }

          }

      }

      &__add {
          padding: 0px 55px !important;
          border: 1px SOLID #C6C6C6 !important;
          border-radius: 16px !important;

          a {
              position: relative !important;
              background-color: #FFFFFF !important;
              color: #000000 !important;
              // @include bridgestone-bold;
              font-size: 14px !important;
              letter-spacing: .1em !important;
              line-height: 1.4em !important;
              text-transform: uppercase !important;

              &:before {
                  content: '+' !important;
                  // @include bridgestone-type;
                  position: absolute !important;
                  color: #C6C6C6 !important;
                  font-size: 48px !important;
                  top: -35px !important;
              }

          }
      }
  }
  //
  //
  // MOBILE LAYOUT, phone
  //
  //

  @include mq(lt-phone) {
    .location-search-tdg .use-current{
      margin-left: 25px; //undo margin set by other stylsheet
    }

    .options-wrapper .landing-options .landing-options__list{
      width: 338px;
    }
  }
}

//modal css, putting down here because its not under the parent "tire-decision-guide" div

#tdg-find-tire-size-cf-en, #tdg-find-tire-size-cf-es, #tdg-learn-more-cf-en, #tdg-learn-more-cf-es{
  display: none; //hide the content fragment so it doesn't appear on page by default
}

.dynamic-top-content:has(.dynamic-top-content--tdg){
  padding: 0px 30px 50px 30px;

  @include mq(lt-phablet) {
    padding: 0px 25px 20px 10px;
  }
}

.dynamic-top-content--tdg{
  .mmimage{
    padding: 20px 5px 20px 5px;
  }

  h2 {
    font-size: 32px;
    width: 100%;
    text-align: center;
    margin: 30px 0px;
  }

  h3 {
    font-size: 18px;
  }

  .modal--show {
    width: 650px;
    height: 632px;
    top: 160px;
    left: 50%;
    padding: 35px;
    margin-left: -325px;
    border-radius: 16px;
    position: absolute;
  }

  .modal-item{
    color: red;

    &:before{
      position: relative;
      top: 31px;
      right: 50px;
      font-size: 36px;
      padding-left: 63px;
    }

    .modal-header{
      margin-left: 67px;
    }
  }

  &.tdg-cf-learn-more{
    p{
      margin-left: 16px;
    }
  }

  .modal-content__list {
    p {
      line-height: 24px;
    }
  }

  .modal-subheader{
    padding-top: 13px;
  }

  .learn-more__list {
    list-style: none;
    padding: 0 20px;
  }

  .dynamic-top-wrap {
    width: 650px;
    height: 632px;
    padding: 35px;
  }

  @include mq(lt-phablet) {
    padding: 0px;

    h2 {
      font-size: 24px;
    }
  }
}

//Spanish specific css
html[data-lang="es_US"] {
  .tire-decision-guide{
    .tire-decision-guide__item label{
      height: 127px;
    }

    .findTireSizeBtn{
      font-size: 12px !important;
      left: -65px;
    }

    .learnMoreBtn{
      font-size: 16px !important;
    }

    @include mq(lt-phone) {
      .learnMoreBtn{
        margin-left: 89px;
      }
    }

    @include mq(lt-tablet) {
      .findTireSizeBtn{
        left: -3px;
      }
    }
  }
}
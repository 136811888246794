.rm-content {

    > div,
    > form > div {
        width: 80%;
        max-width: 700px;
        margin: 0 auto;
        padding: 30px 0;
        // TODO: move to bst/fst and use site variable
        border-bottom: 1px SOLID lightgray;

        &:last-of-type {
            border-bottom: 0;
        }

        h3 {
            width: 100%;
            // TODO: move to bst/fst and use site variable
            font-size: 20px;
            margin: 0 0 25px;
            letter-spacing: 0.05em;
        }
        &.rm-content__rating {
            h3{
                margin-bottom: 8px;
            }
        }
    }

    &__heading {
        text-align: center;

        .h1 {
            margin: 0.5em 0;
        }

        .h2 {
            // TODO: move to bst/fst and use site variable
            font-size: 16px;
            // TODO: move to bst/fst and use site variable
            color: darkgray;
            margin: 0.5em 0;
        }

    }

    &__guidelines {
        p,
        ul li {
            text-align: left;
            // TODO: move to bst/fst and use site variable
            font-size: 16px;
        }

    }

    &__vehicle {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
            width: 30%;

            &:last-of-type {
                width: 100%;
            }

        }

    }

    &__vehicle-year,
    &__vehicle-mileage {
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
            margin-bottom: 3px;
        }

        .input-error {
            color: red;
            font-size: .75rem;
            margin-bottom: 18px;
            margin-left: 5px;
            line-height: 1.2;
        }
    }

    &__attributes {
        ul {
            column-count: 2;
            padding: 0;

            li {
                position: relative;
                list-style: none;
                margin-bottom: 20px;
                padding-left: 30px;
            }
        }
    }

    &__rating {
        > h3 {
            margin-bottom: 8px;
        }
        > p {
            // TODO: move to bst/fst and use site variable
            font-size: 14px;
        }
        .pr-rating-form-group {
            border: none;
            display: grid;
            grid-template-columns: repeat(3, 155px);
            grid-template-areas: 'title inputs error';
            grid-template-rows: 40px;
            margin-top: 15px;
        }
        .pr-rating__title {
            grid-area: title;
            p {
                display: table-cell;
                vertical-align: middle;
                height: 40px;
            }
        }
        .pr-rating {
            grid-area: inputs;
        }
        .pr-error {
            grid-area: error;
        }
        .pr-rating-stars {
            display: flex;
            padding-top: 8px;
            > div:not(:last-child) {
                margin-right: 1px;
            }
        }

        .pr-star {
            display: block;
            width: 23px;
            height: 23px;
            background-size: cover;
            cursor: pointer;
        }

        .pr-star-0-filled {
            background-image: url('data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjIzcHgiIGhlaWdodD0iMjNweCIgdmlld0JveD0iMCAwIDEyIDEyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJpY29uL3N0YXIvZW1wdHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNi4wMDAwMDAsIDAuMDAwMDAwKSIgc3Ryb2tlPSIjRjVDMzAwIiBzdHJva2Utd2lkdGg9IjAuNjgyMzUyOTQxIj4KICAgICAgICAgICAgPGcgaWQ9IlN0YXIvZW1wdHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI2LjAwMDAwMCwgMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik01LjcwNjMzOTEsMC45MzMxNDU3MzcgTDcuMDUzMzAxMjksNC4zNjIxMzAzOCBMMTAuNjAzMDk1NCw0LjY2MzQ1NjA3IEw3Ljg5NzYzODg3LDcuMTE3MjkxODYgTDguNzE2NTMzMTgsMTAuNzUyMjI1IEw1LjcwNjMzOTEsOC44MDU0NTYwOCBMMi42OTYxNDUwMiwxMC43NTIyMjUgTDMuNTE1MDM5MzMsNy4xMTcyOTE4NiBMMC44MDk1ODI3OTgsNC42NjM0NTYwNyBMNC4zNTkzNzY5LDQuMzYyMTMwMzggTDUuNzA2MzM5MSwwLjkzMzE0NTczNyBaIiBpZD0iU3RhciI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
        }
        .pr-star-100-filled {
            background-image: url('data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjIzcHgiIGhlaWdodD0iMjNweCIgdmlld0JveD0iMCAwIDEyIDEyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJTdGFyL2ZpbGxlZCIgZmlsbD0iI0Y1QzMwMCI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJTdGFyIiBwb2ludHM9IjUuNzA2MzM5MSA5LjIxMTc2NDcxIDIuMTc5NjI3NTggMTEuNDkyNTc4NiAzLjEzODQ4NjUgNy4yMzYzNjYyMyA3LjU3OTEyMjUxZS0xNCA0LjM4OTc3NDM5IDQuMTE5MzE4OTIgNC4wNDAxMDQzNiA1LjcwNjMzOTEgOC4wMjQ5NTMyNWUtMTQgNy4yOTMzNTkyOCA0LjA0MDEwNDM2IDExLjQxMjY3ODIgNC4zODk3NzQzOSA4LjI3NDE5MTY5IDcuMjM2MzY2MjMgOS4yMzMwNTA2MSAxMS40OTI1Nzg2Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
        }
    }

    &__review {
        .title-box {
            width: 100%;
        }

        .review-content-box {
            width: 100%;
        }

    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

        > div {
            width: 45%;

            &:first-of-type,
            &:last-of-type {
                width: 100%;
            }

            &:last-of-type {
                padding-left: 30px;
                position: relative;
            }
        }
    }

    &__review,
    &__info {
        input[type=email],
        input[type=text],
        input[type=tel],
        select,
        textarea {
            margin-bottom: 3px;
        }
        .input-error {
            color: red;
            font-size: .75rem;
            margin-bottom: 18px;
            margin-left: 5px;
            line-height: 1.2;
        }
    }

    .button {
        display: block;
        margin: 0 auto 20px;
        cursor: pointer;
    }

    .pr-accessible-text {
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
    }
}
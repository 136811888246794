@import '../../../../../../../resources/scss/breakpoints'; // ui.frontend/src/main/webpack/resources/scss/_breakpoints.scss

.reviews {

    .ratings-summary {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 90%;
        margin: 30px auto 40px;
        padding: 30px;
        background-color: white;

        @include mq(lt-tablet){
            flex-direction: column;
            align-items: center;
            padding: 15px 0;
        }

        .rating-col {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 25%;
            border-right: 1px SOLID lightgray;
            padding-right: 5%;
            margin-right: 5%;
            margin-left: auto;
            text-align: center;

            @include mq(lt-tablet){
                width: 100%;
                margin: 0 auto;
                border: 0;
                padding: 0;
            }

            .write-a-review-button {
                @include mq(lt-phablet){
                    border-top: 0px SOLID lightgray;
                }
            }
        }

        .histogram-col {
            width: 55%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include mq(lt-tablet){
                width: 100%;
            }
        }

        .title {
            font-size: 14px;
            line-height: 1.25em;
            text-align: center;
            margin: 0 0 10px;
        }

        .reviews-inline-rating {

            .average-rating {
                text-align: center;
                font-size: 32px;
                line-height: 1.2em;
            }
        }

        .write-a-review-button {
            width: 100%;

            @include mq(lt-phablet){
                border-top: 1px SOLID lightgray;
            }

            .write-a-review-inline {
                color: black;
                font-size: 14px;
            }

            button {
                cursor: pointer;
                margin: 30px auto 0;
                display: block;

                @include mq(lt-tablet){
                    margin: 20px auto;
                }

                @include mq(lt-phablet){
                    margin: 25px auto 10px;
                    width: 300px;
                }

                @include mq(lt-phone){
                    width: 90%;
                }
            }
        }
    }
}

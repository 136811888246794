@use 'sass:math';
@import '../../../../../../../resources/scss/breakpoints.scss';

// // 1 breakpoints x-x
// $bp-group-1: (
//     bp1: desktop
// ) !default;

// // 2 breakpoints x-x-x
// $bp-group-2: (
//     bp1: tablet,
//     bp2: desktop
// ) !default;

// // 3 breakpoints x-x-x-x
// $bp-group-3: (
//     bp1: phablet,
//     bp2: tablet,
//     bp3: desktop
// ) !default;

// //** Number of max columns in the grid.
// //** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 0 !default;
$grid-gutter-sm-width: 1% !default;
$grid-gutter-md-width: 2% !default;
$grid-gutter-lg-width: 4% !default;
$grid-gutter-xl-width: 6% !default;

$grid-vertical-rule: 1px solid #ccc !default;

.container {
    width: 100%;
	margin: auto;
}

.grid {
	display: grid;

	max-width: 100%;
	margin: auto;

	//display: flex;
	//flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: stretch;

	&.grid-1 > * , &:not([class*="grid-"]) > * {
		width: 100%;
		overflow: hidden; // CSS buggy, needed for grid to respect width

        @include mq(desktop) {
            overflow: visible;
        }
	}

	// only present when grid is in "fixed" column mode:
	// vertical-rule only works when these are present
	.grid-column {
		position: relative;
	}

	// define the various grid options

	&.grid-1 {
		grid-template-columns: 1fr fit-content;
	}
	&.grid-1-1 {
		grid-template-columns: repeat(2, 1fr);
		@include mq(lt-desktop) {
			// keep default
		}
		@include mq(lt-tablet) {
			grid-template-columns: none; // go to one column
		}
	}
	&.grid-1-1-1 {
		grid-template-columns: repeat(3, 1fr);
		@include mq(lt-desktop) {
			// keep default
		}
		@include mq(lt-tablet) {
			grid-template-columns: none;
		}
	}
	&.grid-1-1-1-1 {
		grid-template-columns: repeat(4, 1fr);
		@include mq(lt-desktop) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include mq(lt-tablet) {
			grid-template-columns: none;
		}
	}

	// original grid accounted for:
	/*$grid-list: (
		(1 4),
		(1 3),
		(3 7),
		(1 2),
		(2 3),
		(1 1),
		(3 2),
		(2 1),
		(7 3),
		(3 1),
		(4 1),
		(1 1 1),
		(1 1 2),
		(1 2 1),
		(2 1 1),
		(1 1 3),
		(1 3 1),
		(3 1 1),
		(1 2 2),
		(2 1 2),
		(2 2 1),
		(1 1 1 1),
		(1 1 1 2),
		(1 1 2 1),
		(1 2 1 1),
		(2 1 1 1),
		(1 1 1 1 1)
	);*/

	&.gutters-no {
  		gap: 0;
	}

	&.gutters-sm {
		gap: $grid-gutter-sm-width;
		&.vertical-rule >.grid-column::after {
			right: -$grid-gutter-sm-width;
		}
	}

	&.gutters-md {
		gap: $grid-gutter-md-width;
		&.vertical-rule >.grid-column::after {
			right: -$grid-gutter-md-width;
		}
	}

	&.gutters-lg {
		gap: $grid-gutter-lg-width;
		&.vertical-rule >.grid-column::after {
			right: -$grid-gutter-lg-width;
		}
	}

	&.gutters-xl {
		gap: $grid-gutter-xl-width;
		&.vertical-rule >.grid-column::after {
			right: -$grid-gutter-xl-width;
		}
	}

	&.vertical-rule > .grid-column:not(:last-child) {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
			border-right: $grid-vertical-rule;
		}
	}

	// options not yet accounted for:
	//---------------------------------
	//.direction-column-reverse-mobile // flip order in mobile
	//.two-col-mobile // force two col in mobile

	///// NOT FLEX BUT MANY PROPS STILL APPLY TO GRID SO NEED TO VALIDATE /////

	// Shortcuts
	&.centered {
		justify-content: center;
		align-items: center;
		align-content: center;
		height: 100%;
	}

	&.centered-column {
		justify-content: center;
		align-items: center;
		align-content: center;
		flex-direction: column;
		height: 100%;
	}

	&.row-start {
		flex-direction: row;
		justify-content: flex-start;
	}

	&.row-center {
		flex-direction: row;
		justify-content: center;
	}

	&.row-end {
		flex-direction: row;
		justify-content: flex-end;
	}

	&.column-start {
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
	}

	&.column-center {
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: flex-start;
	}

	&.column-end {
		flex-direction: column;
		align-items: flex-end;
		align-content: flex-end;
		justify-content: flex-start;
	}

	// Wrap
	&.wrap {
		flex-wrap: wrap;
	}

	&.wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	&.nowrap {
		flex-wrap: nowrap;
	}

	// Flex-direction
	&.direction-row {
		flex-direction: row;
	}

	&.direction-reverse {
		flex-direction: row-reverse;
	}

	&.direction-column {
		flex-direction: column;
	}

	&.direction-column-reverse {
		flex-direction: column-reverse;
	}

	// Justify-content
	&.justify-start {
		justify-content: flex-start;
	}

	&.justify-end {
		justify-content: flex-end;
	}

	&.justify-center {
		justify-content: center;
	}

	&.justify-between {
		justify-content: space-between;
	}

	&.justify-around {
		justify-content: space-around;
	}

	// Align-items
	&.items-start {
		align-items: flex-start;
	}

	&.items-end {
		align-items: flex-end;
	}

	&.items-center {
		align-items: center;
	}

	&.items-stretch {
		align-items: stretch;
	}

	&.items-baseline {
		align-items: baseline;
	}

	// Align-content
	&.content-start {
		align-content: flex-start;
	}

	&.content-end {
		align-content: flex-end;
	}

	&.content-center {
		align-content: center;
	}

	&.content-between {
		align-content: space-between;
	}

	&.content-around {
		align-content: space-around;
	}



}

@import '../../../../../../../resources/scss/breakpoints';

.root>.aem-Grid>.responsivegrid>.aem-Grid>.tire-detail {
    max-width: 1140px;
}

.tire-detail {

    .tire-detail__info {
        .cmp-carousel {
            max-width: 65vw;

            @include mq(lt-phablet) {
                margin: 0 auto;
                min-height: 300px;
                max-width: 90vw;
            }
        }
    }

    .tire-detail__main {
        position: sticky;
        top: 70px;
    }

    > div {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;

        > div {

            width: 50%;
            padding: 2%;

            h1 {
                margin: 6px 0 10px;
                span {
                    display: block;
                }
            }

            h3 {
                font-size: 16px;
            }

            > div {

                &.tire-detail__info {

                    .tire-detail__rating {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: baseline;

                        > span {
                            margin-right: 10px;
                            margin-bottom: 10px;
                        }
                    }

                }

                &.tire-detail__sizes {
                    border-top: 1px SOLID lightgray;
                    padding: 15px 0 5px 0;
                    margin: 10px 0 0 0;

                    &.single-size {
                        display: none;
                    }
                }

                &.tire-detail__size {
                    display: none;
                    border-top: 1px SOLID lightgray;
                    padding: 15px 0;
                    margin: 10px 0 0 0;

                    &.single-size {
                        display: block;
                        strong {
                            text-transform: uppercase;
                        }
                    }

                    .h2 {
                        font-size: 1.5em;
                        margin: 10px 0 0;
                    }
                }

                select {
                    cursor: pointer;
                    width: 310px;
                    padding: 0 15px;
                    margin: 10px 0;
                    font-size: 14px;
                    border-color: lightgray;
                    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
                    background-position-x: 276px;
                    -webkit-appearance: none;
                }

                &.tire-detail__specs {
                    display: flex;
                    flex-direction: row;
                    margin: 0 0 10px 0;
                    padding: 0;
                    border-top: 1px SOLID lightgray;
                    border-bottom: 1px SOLID lightgray;

                    > div {
                        width: 48%;
                        padding: 10px 0 10px 20px;

                        &:first-of-type {
                            border-right: 1px SOLID lightgray;
                        }
                    }

                    .h2 {
                        font-size: 32px;
                        margin: 0;
                        text-transform: none;

                        sup {
                            font-size: 0.5em;
                        }
                    }
                }

                &.tire-detail__stores {
                    padding: 10px;
                    background-color: lightgray;

                    .cmp-store-cards {

                        display: flex;
                        flex-direction: column;

                        .cmp-store-heading {
                            display: flex;
                            justify-content: space-between;
                        }

                        .cmp-store-card-list {
                            display: flex;
                            flex-direction: column;
                            overflow: auto;
                        }

                        .store-card {
                            background-color: white;
                        }
                    }
                }
            }
        }
    }
}

//using ID for specificity - currently BST & FST also has the same namespace under the this ID
#section-warranties {
    //Titles are rendering out from content fragment components - https://github.com/adobe/aem-core-wcm-components/issues/596
    .cmp-contentfragment__element-title{
        display: none;
    }
}
@import 'src/main/webpack/resources/scss/breakpoints';

.fsd-modal {
    padding-bottom: 20px;

    @include mq(lt-tablet) {
        overflow: scroll;
    }

    > h1 {
        text-align: center;
        margin: 0 0 30px;
    }

    &__sections {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: flex-start;

        @include mq(lt-tablet) {
            flex-direction: column;
        }

        > div {
            width: 33%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include mq(lt-tablet) {
                width: 90%;
                margin: 0 auto;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: flex-start;
            }

            img {
                width: 120px;
                margin: 0 0 30px;

                @include mq(lt-tablet) {
                    width: 80px;
                    margin: 0 30px 0 0;
                }
            }

            > div {
                height: fit-content;
                padding: 0 10%;
                text-align: center;
                border-right: 1px SOLID lightgray;

                @include mq(lt-tablet) {
                    padding: 10px;
                    text-align: left;
                    border-right: 0;
                    border-bottom: 1px SOLID lightgray;
                }

                b {
                    margin: 0 0 20px;
                    display: block;

                    @include mq(lt-tablet) {
                        margin: 10px 0;
                    }
                }
            }

            &:last-of-type {
                > div {
                    border-right: none;

                    @include mq(lt-tablet) {
                        border-bottom: none
                    }
                }
            }
        }
    }
}
@import '../../../../../../../../resources/scss/breakpoints';

.map {
    min-height: 40vw;

    @include mq(lt-tablet) {
        width: 100% !important;
        height: 40vh;
        position: relative;
        z-index: 0;
    }

    &__render {
        position: relative;
        width: 100%;
        height: 100%;

        .dynamic-top-container & {
            width: 385px;
            height: 200px;

            @include mq(lt-phablet) {
                width: 100%;
                height: calc(100vh - 410px);
            }
        }
    }

    .bm_bottomRightOverlay {
        display: none;
    }
}

.MicrosoftMap {
    z-index: 0;
}
@import "../../../../../../../resources/scss/breakpoints";

body {
  &.overlay-active {
    overflow: hidden;
  }
  &.flyout,
  &.modal {
    .dynamic-top-container {
      display: grid;
      overflow: scroll;
      pointer-events: none; 
    }
  }
  &.flyout {
    .dynamic-top-container {
      grid-template-areas:  "modal modal modal"
                            "modal modal modal"
                            "modal modal modal";
      grid-template-rows: minmax(0, 0) 100svh minmax(0, 0);
      grid-template-columns: 1fr 1fr 1fr;
      left: unset;
      position: fixed;
      @include mq(phone) {
        grid-template-areas:  ". modal modal"
                              ". modal modal"
                              ". modal modal";
        grid-template-columns: 1fr 1fr minmax(80%, 560px);
      }
      @include mq(phablet) {
        grid-template-areas:  ". . modal"
                              ". . modal"
                              ". . modal";
        grid-template-columns: 1fr 1fr minmax(400px, 500px);
      }
    }
  }
  &.modal {
    .dynamic-top-container {
      grid-template-areas:  "modal modal modal"
                            "modal modal modal"
                            "modal modal modal";
      grid-template-rows: minmax(0, 0) 100vh minmax(0, 0);
      grid-template-columns: 1fr 1fr 1fr;
      position: fixed;
      @include mq(phone) {
        grid-template-areas:  ". . ."
                              ". modal ."
                              ". . .";
        grid-template-columns: 1fr minmax(90%, 100%) 1fr;
      }
      @include mq(phablet) {
        grid-template-areas:  ". . ."
                              ". modal ."
                              ". . .";
        grid-template-columns: 1fr minmax(25%, 65%) 1fr; //1fr minmax(auto, 85%) 1fr;
        grid-template-rows: minmax(5%, 50%) auto minmax(5%, 50%); //50px auto minmax(50px, auto);
      }
      @include mq(desktop) {
        grid-template-areas:  ". . ."
                              ". modal ."
                              ". . .";
        grid-template-columns: 1fr minmax(25%, 65%) 1fr; //75%
        grid-template-rows: minmax(5%, 50%) auto minmax(5%, 50%); //minmax(5%, 50%) minmax(auto, 90%) minmax(5%, 50%); 
      }
    }
  }
  .dynamic-top-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    z-index: 10001;
  }
  .dynamic-top-wrap {
    grid-area: modal;
    pointer-events: auto; 

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40px auto; //45px
    grid-template-areas: "close"
                         "content";
    .modal-close {
      grid-area: close;
      position: relative;
      background-color: #ffffff;
      text-align: right;
      border-radius: 8px 8px 0px 0px;
      button {
        background-color: white;
        border: none;
        color: #c6c6c6;
        cursor: pointer;
        font-size: 24px;
        margin-right: 16px;
        margin-top: 9px;
      }
    }
    .dynamic-top-content {
      grid-area: content;
      background-color: #ffffff;
      border-radius: 0px 0px 8px 8px;
      overflow: auto;
    }
  }
}
@import "../../../../../../../resources/scss/breakpoints";

.simple-list {

  .list-heading {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    > h1, h2, h3, h4 {
      margin: 0;
    }

    .toggle-content {
      display: flex;
      position: relative;
      justify-content: center;
      height: 100%;
      align-items: center;
      margin-left: 20px;

      .expand {
        cursor: pointer;
        stroke: black;
        width: 25px;
        height: 15px;
        transition: all 0.3s linear;
        top: 50%;

        &.open {
          transform: rotateX(180deg);
          transition: all 0.3s linear;
          stroke: black;
        }
      }
    }
  }

  ul {

    &.collapsed {
      max-height: 1px;
      overflow: hidden;
      transition: max-height 0.5s cubic-bezier(0, 1, .5, 1);
    }

    &.active {
      height: auto;
      max-height: 5000px;
      transition: max-height 1.5s ease;
      display: block;
    }
  }

  &.dealers-locations {

    ul {
      column-count: 4;
      column-gap: 100px;
      list-style: none;
      margin-left: -40px;

      @include mq(lt-tablet) {
        column-count: 3;
      }

      @include mq(lt-phone) {
        column-count: 2;
      }

      a {
        text-decoration: none;
        line-height: 1.75em;
      }
    }
  }
}
.article-card {
	width: 100%;
	margin: 1em auto;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: space-around;
	
    border: 1px solid black; 
	
	.article-card__copy{
        padding: 1em;
	}
	
}
@import '../../../../../../../resources/scss/breakpoints';

.tire-detail-specs {

    .h1 {
        text-align: center;
    }

    > span {
        font-size: 12px;
        display: block;
        width: 310px;
        margin: 0 auto;

        @include mq(lt-phone) {
            width: 90%;
        }
    }

    select {
        display: block;
        position: relative;
        cursor: pointer;
        width: 310px;
        padding: 5px 15px;
        margin: 10px auto 0;
        font-size: 14px;
        border-color: lightgray;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
        background-position-x: 276px;
        -webkit-appearance: none;

        @include mq(lt-phone) {
            width: 90%;
            background-position-x: 95%;
        }

    }

    &__header {

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 80%;
        padding: 20px 0;
        margin: 40px auto 0;
        text-align: center;
        font-style: italic;
        border-top: 1px SOLID gray;
        border-bottom: 1px SOLID gray;

        > div {

            @include mq(lt-phablet) {
                padding: 10px;
            }

            > span {
                display: block;
            }

            .h1 {
                text-align: center;
            }

        }

        .h1 {
            margin: 0;
            text-align: left;
        }

        .h1.tread-depth {

            text-transform: none;

            sup {
                vertical-align: top;
                font-size: 0.75em;
                line-height: 35px;
            }

            sub {
                vertical-align: bottom;
                font-size: 0.75em;
                line-height: 35px;
            }
        }

    }

    &__full {

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        height: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, .5, 1);

        @include mq(lt-tablet) {
            flex-direction: column;
        }

        &.active {
            height: auto;
            max-height: 5000px;
            transition: max-height 1.5s ease;
        }

        &-left,
        &-right {

            width: 46%;
            display: flex;
            flex-direction: column;
            margin-top: 50px;

            @include mq(lt-tablet) {
                width: 100%;
                margin-top: 0;
            }

            div {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                width: 100%;
                padding: 20px;
                border-top: 1px SOLID gray;
                font-size: 14px;

                @include mq(lt-tablet) {
                    flex-direction: column;
                }

                &:first-of-type {
                    @include mq(lt-tablet) {
                        border-top: 0;
                    }
                }

                &:last-of-type {
                    border-bottom: 1px SOLID gray;

                }

            }

        }

    }

    a.button {
        cursor: pointer;
        margin: 40px auto 0;
        display: block;
    }

    &__variants {
        display: none;
        height: 60px;
        max-height: 60px;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, .5, 1);

        &.active {
            display: block;
            margin: 20px 0;
            padding: 20px 30px;
        }

        &.expanded {
            height: auto;
            max-height: 5000px;
            transition: max-height 1.5s ease;
        }

        .h1 {
            cursor: pointer;
            margin: 0;
            font-size: 20px;
            text-align: left;
            position: relative;

            &:after {
                content: "+";
                position: absolute;
                right: 0;
            }

            &.open {
                border-bottom: 1px SOLID lightgray;
                padding: 0 0 20px 0;

                &:after {
                    //content: "\2014";
                    content: "-";
                }
            }
        }

        &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .tire-variant {
                width: 45%;
                display: none;

                &.show {
                    display: block;
                }

                .h2 {
                    text-align: center;
                    margin: 30px 0 0;
                    padding: 0 0 20px 0;
                    justify-content: center;
                    font-size: 20px;
                }

                > div {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    width: 100%;
                    border-bottom: 1px SOLID gray;
                    font-size: 14px;
                    padding: 15px 0;

                    &:last-of-type {
                        border: 0;
                    }

                }
            }

        }
    }
}
@import '../../../../../../../resources/scss/breakpoints';

.tire-comparison {
    display: none;
    position: absolute;
    width: 100%;
    background-color: white;
    // BATO2020-1072 :: removing z indexes to test necessity
    ////////////////////z-index: 11;
    overflow-y: auto;
    transition: all 300ms ease-in;

    &--active {
        display: block;
        transition: all 300ms ease-in;
        z-index: 2;
    }

    .tire-comparison__header {
        width: 100%;
        padding: 20px 0 40px;

        > div {
            width: 80%;
            margin: 0 auto;

            a {
                position: relative;
                padding-left: 26px;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    border: 7px solid transparent;
                }

                &:before {
                    top: 2px;
                    left: 0;
                    border-right-color: black
                }

                &:after {
                    left: 3px;
                    top: 2px;
                    border-right-color: white;
                }
            }

            h2 {
                line-height: 1.8em;
            }

            &:nth-of-type(2) {
                width: 100%;
                background-color: black;
                color: white;
                text-align: center;
                height: 50px;
                line-height: 50px;
            }

            &:nth-of-type(3) {
                width: fit-content;
                margin: 30px auto 0;
                font-size: 14px;
            }

            .switch {
                position: relative;
                display: inline-block;
                width: 38px;
                height: 20px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: gray;
                -webkit-transition: .4s;
                transition: .4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
            }

            input:checked + .slider {
                background-color: black;
            }

            input:focus + .slider {
                box-shadow: 0 0 1px black;
            }

            input:checked + .slider:before {
                transform: translateX(18px);
            }

            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }
    }

}

.cmp-tire-comparison {
    width: 100%;
    height: calc(100vh - 88px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding-bottom: 50px;

    &__flyout {
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;

        .flyout__card {
            position: relative;
            width: 110px;
            display: flex;
            flex-direction: column;

            &:nth-of-type(3) {
                @include mq(lt-phablet) {
                    display: none;
                }
            }

            &__name {
                height: 30px;
                line-height: 1em;
            }

            .remove-tire {
                // BATO2020-1072 :: removing z indexes to test necessity
                ////////////////////z-index: 11;
                position: absolute;
                top: -10px;
                right: 5px;
                font-size: 1.5em;
                cursor: pointer;

                &::after {
                    content: '×';
                }
            }

            &__image {
                width: 110px;

                &__placeholder {
                    height: 120px;
                }

                img {
                    width: 100%;
                }
            }
        }

        a.button {
            cursor: pointer;

            &.disabled {
                pointer-events: none;
            }
        }
    }

    &__col {
        width: 260px;
        margin: 0 15px;
        padding: 0 0 10px 0;
        display: flex;
        flex-direction: column;

        @include mq(lt-phablet) {
            width: 45%;
            margin: 0 5px;
        }

        > div {
            width: 100%;

            @include mq(lt-tablet) {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
        }

        .performance {
            margin: 0 auto;
            text-align: left;
            overflow: hidden;
            position: relative;

            &__row {
                display: flex;
                width:100%;
            }


            &__rating-bar {
                display: flex;
                width: 100%;
                height: 6px;
                background-color: grey;
            }

            &__rating-bar-inner {
                background-color: red;
                height: 6px;
            }

            .data-stat-0{
                width: 0%;
            }

            .data-stat-1{
                width: 10%;
            }

            .data-stat-2{
                width: 20%;
            }

            .data-stat-3{
                width: 30%;
            }

            .data-stat-4{
                width: 40%;
            }

            .data-stat-5{
                width: 50%;
            }

            .data-stat-6{
                width: 60%;
            }

            .data-stat-7{
                width: 70%;
            }

            .data-stat-8{
                width: 80%;
            }

            .data-stat-9{
                width: 90%;
            }

            .data-stat-10{
                width: 100%;
            }
        }
    }

    &__add {
        width: 260px;
        height: 300px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        @include mq(lt-phablet) {
            display: none;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            text-align: center;
            text-decoration: none;
            pointer-events: none;
        }
    }
}
@import '../../../../../../../resources/scss/breakpoints'; // ui.frontend/src/main/webpack/resources/scss/_breakpoints.scss

.reviews {

    .reviews-sort {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 40px;
        width: 90%;
        margin: 0 auto;
        justify-content: space-between;

        @include mq(lt-phablet) {
            font-size: 14px;
        }

        .custom-select {

            select {
                -webkit-appearance: none;
                background-position-x: 135px;
                border: 0;
                cursor: pointer;
                font-size: 14px;
                margin: 10px 0;
                padding: 15px;
                width: 160px;
            }
        }
    }
}

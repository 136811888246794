@import '../../../../../../../resources/scss/breakpoints';

.store-details {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include mq(lt-tablet) {
        flex-direction: column;
    }

    &-info {
        width: 390px;

        @include mq(lt-tablet) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        &__header {
            padding: 20px;

            @include mq(lt-tablet) {
                width: 100%;
                padding: 20px 0 0;
            }

            &-see-all {
                display: block;
                position: relative;
                padding-left: 26px;
                margin-bottom: 15px;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    border: 7px solid transparent;
                }

                &:before {
                    top: 2px;
                    left: 0;
                    border-right-color: black
                }

                &:after {
                    left: 3px;
                    top: 2px;
                    border-right-color: white;
                }
            }

            h2 {
                line-height: 1.1em;
            }

            a.button {
                margin-top: 20px;
                padding: 0 40px;
            }

        }

        &__wrapper {

            @include mq(lt-tablet) {
                width: 50%;
            }

            @include mq(lt-phablet) {
                width: 100%;
            }

            > div {
                border-top: 1px SOLID grey;
                font-size: 14px;
                line-height: 18px;

                &:nth-of-type(1){
                    border-top: 0;
                }

                a :not(.button) {
                    text-decoration: underline;
                }

            }

        }

        &__hours {

            @include mq(lt-tablet) {
                width: 50%;
            }

            @include mq(lt-phablet) {
                width: 100%;
            }

            ul {
                padding: 0;
                margin: 0;
                width: 80%;

                @include mq(lt-tablet) {
                    width: 100%;
                }

                li {
                    list-style-type: none;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    font-size: 14px;
                    line-height: 18px;

                    div {
                        width: 35%;
                        text-align: left;

                        &:nth-of-type(2) {
                            width: 60%;
                        }

                        span {
                            text-transform: uppercase;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }

    .map {

        .list-view-cta {
            display: none;
        }

    }
}
@import '../../../../../../../../resources/scss/breakpoints';

body.flyout .dynamic-top-container {
    grid-template-columns: 1fr 1fr minmax(385px,385px);
    overflow: hidden;
}

.cmp-store-cards {
    width: 350px;
    transition: 500ms ease-out;
    opacity: 1;
    padding-right: 15px;
    max-height: 800px;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;

    .tire-detail__stores-wrapper & {
        max-height: 900px;
    }

    &--fade-out {
        transition: 500ms ease-out;
        opacity: 0;
    }

    > h5 {
        height: 28px;
        line-height: 12px;
        margin: 0 auto 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include mq(lt-tablet) {
        width: 90%;
        margin: 0 auto;
        padding: 40px 0 0 0;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

        > div {
            display: flex;
            flex-direction: row;
            width: 100%;
            overflow: scroll;
        }
    }

    .dynamic-top-container & {
        width: 100%;
        padding: 15px 15px 0;

        @include mq(lt-tablet) {

            > div {
                display: flex;
                flex-direction: column;
                width: 350px;
            }
        }

        @include mq(lt-phablet) {
            width: 100vw;

            > div {
                display: flex;
                flex-direction: row;
                width: 100%;
                overflow: scroll;
            }
        }

        > h5 {
            display: none;
        }
    }

    .basket-transfer-filter {
        cursor: pointer;
        font-size: 12px;
        width: 60%;
        height: 28px;
        margin-left: auto;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        opacity: 1;
        transition: all 300ms ease;

        @include mq(lt-tablet) {
            margin: 0 auto 10px;
            width: fit-content;
        }

        &.hide {
            pointer-events: none;
            opacity: 0;
            transition: all 300ms ease;
        }

        * {
            cursor: pointer;
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 38px;
            height: 20px;
            margin: 0 5px 0 0;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: gray;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked + .slider {
            background-color: black;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px black;
        }

        input:checked + .slider:before {
            transform: translateX(18px);
        }

        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }

        p {
            font-size: 12px;
            line-height: 12px;
            margin: 0;
        }
    }

    .cards-wrapper {
        transition: 500ms ease-out;
        opacity: 1;

        &.fade-out {
            transition: 500ms ease-out;
            opacity: 0;
        }
    }

    .store-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;
        padding: 15px 0;

        @include mq(lt-tablet) {
            margin-right: 10px;
            flex: 1 0 320px;

            .store-cards-list-view & {
                margin: 0 0 15px 0;
                flex: 1 0 240px;
            }
        }

        .dynamic-top-container & {

            @include mq(lt-tablet) {
                margin-right: 0;
                flex: 1;
            }

            @include mq(lt-phablet) {
                margin-right: 10px;
                flex: 1 0 320px;
            }

        }

        &.hide {
            display: none;
        }

        &_distance {
            margin-left: 10px;
        }

        .basket-transfer {
            cursor: pointer;

            &[data-href="false"] {
                display: none;
            }
        }
    }
}

.cmp-online-retailers {
    opacity: 0;
    width: 350px;
    transition: 500ms ease-out;
}

.redirect-modal {
    width: 80%;
    margin: 0 auto 40px;
    text-align: center;

    @include mq(lt-phablet) {
        margin-top: 50%;
    }

    button {
        cursor: pointer;
    }
}
@import '../../../../../../../resources/scss/breakpoints.scss';

.footer {
	footer {
		margin: 0 auto;
		display: flex;
		flex-flow: row wrap;
		flex: 1 100%;
	}

	&__logo {
		display: flex;
		width: 100%;

		&--left {
			text-align: left;
		}

		&--center {
			text-align: center;
		}

		&--right {
			text-align: right;
		}
	}

	&__links {
		display: flex;
		width: 100%;
		border-bottom: 1px solid;
		justify-content: space-between;

		.list-heading {
			pointer-events: none;
		}

		@include mq(lt-tablet) {
			border-bottom: 0;
			flex-flow: column nowrap;

			.simple-list {
				border-bottom: 1px solid;

				&:first-child {
					border-top: 1px solid;
				}

				.list-heading {
					display: flex;
					align-items: center;
					padding: 0 19px 0 32px;
					height: 52px;
					pointer-events: auto;

					h3 {
						margin: 0;
						width: 100%;
					}
				}

				ul {
					display: none;
				}

				&--selected {
					> ul {
						display: block;
					}
				}
			}
		}

	}

	&__copyright {
		width: 50%;
		text-align: left;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		flex-direction: column-reverse;

		p {
			margin-bottom: 0;
		}

		@include mq(lt-tablet) {
			width: 100%;
			text-align: center;
			order: 4;
			margin-top: 17px;
			align-items: center;
			justify-content: center;
		}

	}

	&__social {
		display: flex;
		width: 50%;
		text-align: right;
		justify-content: flex-end;

		ul li {
			display: inline;
		}

		@include mq(lt-tablet) {
			width: 100%;
			text-align: center;
			justify-content: center;
		}
	}

	.simple-list {
		display: flex;
		flex-flow: column nowrap;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin-bottom: 0;
	}
}

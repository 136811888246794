@import '../../../../../../../resources/scss/breakpoints'; // ui.frontend/src/main/webpack/resources/scss/_breakpoints.scss

.reviews {

    .ratings-and-reviews {
        padding-top: 40px;

        > .heading {
            text-align: center;
            margin-bottom: 40px;
        }

        background-color: lightgray;
    }

    .reviews-list {
        list-style-type: none;
        width: 90%;
        margin: 0 auto;
        padding: 0;

        > li {
            margin: 20px 0;
            padding: 0;
        }

        .stars > li:not(:first-child) {
            padding-left: 4px;
        }
    }

    .empty-reviews-list {
        height: 1px;
        margin-bottom: 60px;

        @include mq(lt-tablet) {
            margin-bottom: 40px;
        }
    }
}

.aem-AuthorLayer-Edit {
    .store-locator {
        opacity: 1 !important;

        &__location-unknown {
            display: block !important;
            position: relative !important;
            height: auto !important;
            left: auto !important;
            right: auto !important;
        }
    }
}
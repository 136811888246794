@import '../../../../../../../resources/scss/breakpoints'; // ui.frontend/src/main/webpack/resources/scss/_breakpoints.scss
.reviews {
    .recommend-percent {
        height: 120px;
        display: flex;
        align-items: center;
        border: 1px solid #9B9B9B;

        @include mq(lt-tablet) {
            margin: 20px 0;
        }

        .percent {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            font-size: 32px;
        }

        .label-group {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            background-color: #9B9B9B;
            color: #ffffff;
        }

        .recommend-icon {
            padding: 20px;
        }

        .recommend-label {
            padding: 20px;
            font-size: 24px;
        }
    }
}

@import '../../../../../../../resources/scss/breakpoints'; // ui.frontend/src/main/webpack/resources/scss/_breakpoints.scss

.ratings-app {

    .inline-rating {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 5px;
        height: 20px;

        &.center {
            justify-content: center;
        }

        &.wrap {
            flex-direction: column;
            flex-wrap: wrap;

            @include mq(lt-phablet){
                flex-direction: row-reverse;
                flex-wrap: nowrap;
                justify-content: center;
                border-bottom: 1px SOLID lightgray;
                margin: 0 auto 15px;
                width: 100%;
                padding-bottom: 10px;
            }

            .average-rating {
                font-size: 32px;
                line-height: 1.2em;
                margin-bottom: 5px;

                @include mq(lt-phablet){
                    font-size: 22px;
                    padding-left: 10px;
                }
            }
        }

        a,
        .btn-inline {
            color: #4a4a4a;

            &:hover,
            &:active {
                color: #000000;
            }
        }

        .write-review {
            display: flex;
            align-items: center;
            padding-left: 12px;
            font-size: 14px;
        }

        .write-review-first {
            font-size: 14px;
        }

        .product-catalog & {
            margin: 10px 0;
        }

        .product-carousel & {
            margin-bottom: 20px;
        }
    }
}

// product detail specific styles
// RAUMER: may need to uncomment
/* .product-detail {
    .mobile .ratings-app .inline-rating {
        justify-content: center;
        margin: 35px auto 45px;
    }

    .desktop .ratings-app .inline-rating {
        .write-review,
        .write-review-first {
            a,
            .btn-inline {
                color: inherit;

                &:hover,
                &:active {
                    color: inherit;
                }
            }
        }
    }
} */

@import '../../../../../../../../resources/scss/breakpoints';

.store-filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 20px auto;

    @include mq(lt-tablet) {
        flex-wrap: wrap;
    }

    .caret {
        position: relative;
        margin-left: 10px;

        @include mq(lt-phablet) {
            position: absolute;
            margin: 0;
            right: 10px;
            top: 0;
        }

        &::before {
          content: '';
          position: absolute;
          top: -2px;
          left: -5px;
          border-top: 7px solid black;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
        }

        &::after {
          content: '';
          position: absolute;
          top: -2px;
          left: -2px;
          border-top: 4px solid white;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }
    }

    .close-overlay {
        z-index: 201;
        position: absolute;
        top: -5px;
        right: 10px;
        font-size: 32px;
        cursor: pointer;

        &::after {
            content: '×';
        }
    }

    &__tab-content {

        @include mq(lt-tablet) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin: 0 auto;
        }

        z-index: 0;
        margin-right: auto;
        position: relative;

        input[type=radio] {
            position: absolute;
            left: -99999em;
            top: -99999em;
            opacity: 1;
            z-index: 1;

            + label {
                position: relative;
                cursor: pointer;
                float: left;
                margin-right: -1px;
                // white-space: nowrap; // removing for i18n fix. leaving in for now in the event of a regression
            }

            &:checked+label {
                z-index: 1;
            }
        }
    }

    &__filters {
        width: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        margin-left: auto;
        margin-top: 10px;

        @include mq(lt-tablet) {
            justify-content: center;
            width: 100%;
            height: 45px;
            margin-top: 20px;
            padding: 30px 0 20px;
        }

        @include mq(lt-phone) {
            margin: 20px 20px 0;
        }

        > div {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: fit-content;

            @include mq(lt-phone) {
                padding: 0;
                margin: 0;
                width: fit-content;
            }
        }

        &__location {
            border-right: 1px SOLID;

            @include mq(lt-phone) {
                min-width: 85px;
            }

            span {
                padding: 0 20px 0 10px;
                text-decoration: underline;
                cursor: pointer;

                @include mq(lt-phone) {
                    padding: 0 5px 0 10px;
                }
            }

            &__select {
                display: none;
                position: absolute;
                z-index: 101;
                background-color: white;
                width: 340px;
                height: auto;
                right: 0;
                top: 0;
                padding: 20px;
                box-sizing: border-box;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
                list-style: none;
                margin: 0;
                transition: all 300ms ease-in-out;

                @include mq(lt-tablet) {
                    left: 0;
                }

                @include mq(lt-phone) {
                    box-shadow: none;
                    height: 100vh;
                    left: 0;
                    right: initial;
                    top: 0;
                    width: 100vw;
                    z-index: 201;
                    position: fixed;
                }

                &--active {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    transition: all 300ms ease-in-out;
                }

                &--fade-out {
                    opacity: 0;
                    transition: all 300ms ease-in-out;
                }
            }
        }

        &__distance {
            border-right: 1px SOLID;
            padding: 0 25px 0 10px;

            @include mq(lt-phone) {
                min-width: 80px;
            }

            &__display  {

                padding: 0 10px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                @include mq(lt-tablet) {
                    font-size: 14px;
                }

                @include mq(lt-phablet) {
                    flex-direction: column;
                }

                b {
                    margin-left: 5px;
                }
            }

            &__select {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 101;
                background-color: white;
                width: 270px;
                height: auto;
                padding: 0 20px 10px;
                box-sizing: border-box;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);

                @include mq(lt-phone) {
                    right: initial;
                }

                p {
                    font-size: 12px;
                    letter-spacing: 0.1em;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin-bottom: 10px;

                        input[type=radio] {
                            display: none;
                            //margin: 0 10px 15px 0;

                            & + *::before {
                                content: "";
                                display: inline-block;
                                vertical-align: bottom;
                                width: 22px;
                                height: 22px;
                                margin: 0 10px 0 0;
                                border-radius: 50%;
                                border-style: solid;
                                border-width: 1px;
                            }
                        }

                        label {
                            cursor: pointer;
                            letter-spacing: 0.1em;
                        }
                    }
                }
            }

            &__select--active {
                display: flex;
                flex-direction: column;
            }
        }

        &__sortby {
            padding: 0 10px;

            @include mq(lt-phone) {
                min-width: 85px;
                text-align: center;
            }

            &__display  {
                padding: 0 10px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                @include mq(lt-tablet) {
                    font-size: 14px;
                }

                @include mq(lt-phablet) {
                    flex-direction: column;
                }

                b {
                    margin-left: 5px;
                }
            }

            &__select {
                display: none;
                position: absolute;
                z-index: 101;
                background-color: white;
                width: 270px;
                height: auto;
                right: 0;
                top: 0;
                padding: 0;
                box-sizing: border-box;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
                list-style: none;
                margin: 0;

                li {
                    cursor: pointer;
                    padding: 10px 20px;
                }
            }

            &__select--active {
                display: flex;
                flex-direction: column;
            }
        }
        .store-use-current--error {
            display: none;
            margin: 3px 0px 0px 9px;

            h4 {
            font-size: 14px;
            font-weight: 400;
            }

            p{
            font-size: 14px;
            color: #757575;
            }

            &:before{
            content: "";
            background-image: url('data:image/svg+xml,<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Group 13932"><g id="Group 13931"><g id="Group 13930"><g id="Group 13929"><circle id="Ellipse 21" cx="9.5" cy="9.5" r="9.5" fill="%23951200"/><path id="!" d="M9.38295 15C9.00145 15 8.67293 14.873 8.3974 14.6191C8.13247 14.355 8 14.0402 8 13.6745C8 13.3089 8.13247 12.9991 8.3974 12.7452C8.67293 12.4912 9.00145 12.3643 9.38295 12.3643C9.76445 12.3643 10.0877 12.4912 10.3526 12.7452C10.6175 12.9991 10.75 13.3089 10.75 13.6745C10.75 14.0402 10.6175 14.355 10.3526 14.6191C10.0877 14.873 9.76445 15 9.38295 15ZM8.30202 7.1385V4H10.448V7.1385L10.2254 11.1607H8.52457L8.30202 7.1385Z" fill="white"/></g></g></g></g></svg>');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 26px;
            height: 19px;
            left: -6px;
            position: relative;
            right: 0;
            top: 2px;
            margin-right: 5px;
            }

            @include mq(lt-phone) {
            margin: 20px 0 20px 5px;
            &:before{
                top:0px;
                margin-right: 0px;
            }
            }
        }
    }
}
html[data-lang="es_US"] {
    .store-filter__filters .store-use-current--error::before {
        width: 36px;
    }
}
.tire-detail-features {
    padding: 20px;
    text-align: center;
    background-color: lightgray;

    &__icon-wrapper {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;

        div {
            padding: 0 10px;
            max-width: 100px;

            span {
                display: block;
                background-color: white;
                padding: 25px;
                margin-bottom: 15px;
                border-radius: 50%;
                color: red;
                font-size: 22px;
            }
        }
    }
}
@import '../../../../../../../resources/scss/breakpoints';

.cmp-tire-card {

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    text-align: center;
    max-width: 360px;

    &:hover {
        cursor: pointer;
    }

    .will-it-fit__fit-microcopy{ //added here to hide microcopy only for tire-cards that are not in tire detail page 
        display: none;
    }

    // feels like some of this is redundant to what should be in the tire-rating component
    .tire-rating {
        &__summary {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            font-size: 14px;


            .stars {
                width: 80px;
                height: 16px;

                &:before {
                    width: 80px;
                    background-size: 16px;
                    height: 16px;
                }

                > span {
                    height: 16px;

                    &:before {
                        background-size: 16px;
                        height: 16px;
                    }

                }
            }

            .averageRating {
                margin-left: 3px;
                margin-right: 2px;
            }
        }
    }

}


.cmp-tire-card-compare {
    width: 260px;

    .remove-comparison-card {
        // BATO2020-1072 :: removing z indexes to test necessity
        ////////////////////z-index: 201;
        position: absolute;
        top: -5px;
        right: 10px;
        font-size: 32px;
        cursor: pointer;

        &::after {
            content: '×';
        }
    }

    &__image {
        width: 200px;
        margin: 30px auto 20px;

        @include mq(lt-tablet) {
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    h3 {
        min-height: 40px;
        width: 200px;
    }

    &__rating {
        width: 90%;
        margin: 0 auto;
        padding: 10px 0;
        height: 40px;
        display: flex;
        justify-content: space-evenly;

        @include mq(lt-tablet) {
            height: 50px;
            align-items: flex-start;
            padding: 0;
            flex-wrap: wrap;
        }

        div {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            font-size: 12px;

            div {
                padding: 0 2px;
            }
        }
    }
}

.cmp-tire-card-details {
    width: 100%;

    .details {
        width: 100%;
        position: relative;

        .heading {
            width: 100%;
            height: 40px;
            line-height: 30px;
            text-align: left;
            padding: 5px 20px;
            box-sizing: border-box;
        }

        .expand {
            position: absolute;
            right: 15px;
            top: 0px;
            text-decoration: none;
            font-style: inherit;
            font-size: 30px;
            cursor: pointer;
        }

        hr {
            border: 0;
            background-color: gray;
            left: 10%;
            width: 80%;
            position: relative;
        }

        .overview,
        .performance,
        .specifications {
            width: 100%;
            margin: 0 auto;
            text-align: left;
            overflow: hidden;
            max-height: 1px;
            transition: max-height 0.5s cubic-bezier(0, 1, .5, 1);

            &.active {
                height: auto;
                max-height: 5000px;
                transition: max-height 1.5s ease;

                hr {
                    position: absolute;
                }
            }
        }

        .performance {
            > div {
                padding: 10px 20px 20px;
                width: 100%;
            }
        }

        .overview,
        .specifications {
            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 80px;
                padding: 0 30px;
            }
        }

        .overview {
            > div:last-of-type {
                height: fit-content;
                padding: 0;
            }
        }

        .highlight {
            background-color: lightgray;
            border-left: 5px SOLID red;
            padding-left: 15px;
        }
    }

    .button {
        width: 80%;
        margin: 10px 25px;

        @include mq(lt-tablet) {
            margin: 5px auto;
        }
    }
}

.cmp-tire-card-recommended {
    margin: 30px 0;
    justify-content: flex-start;
    background-color: white;
    padding: 10px 20px;

    .cmp-tire-card__buttons{
        justify-content: center;
    }

    .button{
        margin: 8px;
    }
}

.cmp-tire-card-search {

    &[data-hide='true'] {
        display: none;
    }

    &.front,
    &.rear {
        position: relative;
        padding-top: 30px;

        &:before {
            display: block;
            background-color: red;
            color: white;
            content: attr(data-front);
            padding: 3px 10px;
            width: fit-content;
            font-size: 12px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }
    }

    &.rear {

        &:before {
            content: attr(data-rear);
        }
    }

    .highest-rated-show & {

        &.highest-rated {
            position: relative;
            padding-top: 30px;

            &:after {
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                box-sizing: border-box;
                content: attr(data-highestRated);
                background-color: black;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfgAAAAqCAIAAADQy964AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAbFJREFUeNrs3TEvA3EcBuAfMRJGJhLM+ARiNIpYiX4A9g6EwQfoB6iwSsTWjaG7MCNhYhLCKvG/aEgqtLq4uzxPOvSau+Ud3vvl+r+7CAAAAAAAAAAAAAAAAAD4DxOjMTMtBoDyqlfjtCYGgPKO82/N7DM/JwygW/0iKJCtSuvL9rowAMo7zhvqARN9ycf5D2uLIgG60ieCoozzN0ftP06uxO2DbABFX+RyHx+L2akYGYrVxWyzTWr5w0Y8vcTFddzdK31A0RfE8V7vi+Uvr+LpNZaq8fwqSKDFNfrc2dmPibEej02nh4OGlgdM9LmX+vqsFsODfz6wspcVPYCiL2HXpyk+tfxJU3KAoi9j16eWX9jILtADKPoSdr2WB37nz9hcS/V90anB0w5aHlD0BTbeaQWOZyEAir7Yvt8n1ds+gKInj7qc1me9igRQ9OUY528fsjWUy9X2px145xTwiwER5NnnqJ6afbf+dTPUSTN7euVWpXUmmJkSFUAxndbisRHblR9XWKa6vzmK87qoAIop9XjHG6bSDpsrogIAAAAAAAAAAAAAAAAAAAAA8uxdgAEAjHBkuLG4UWsAAAAASUVORK5CYII=');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                color: white;
                display: block;
                font-size: 9pt;
                margin: 0;
                padding: 3px 10px;
                width: 100%;
            }
        }
    }
}
.cmp-video {
    position: relative;
    width: 100%;
}

.cmp-video-wrapper {
    position: relative;
    margin: 0 auto;
    min-width: 300px;
    max-width: 770px;

    .cmp-video-poster {
        position: relative;
        transition: opacity 0.3s;
    }

    .play-button-overlay {
        cursor: pointer;
        position: absolute;
        width: 15%;
        height: 15%;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        z-index: 1;

        div {
            h4 {
                font-size: 16px;
                color: #fff;
                text-align: center;
            }
        }

        svg {
            width: 100%;
            height: 100%;
            transition: all .2s ease;
            fill: #fff;
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));

            &:hover {
                fill: #000;
            }
        }
    }

    .cmp-video-iframe-wrapper {
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.5s;

        video,
        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border: none;
        }

    }

    picture {
        display: block;
        text-align: center;
        overflow: hidden;
        width: 100%;
    }
}

// For Modal
.modal{
  .video-cmp-iframe {
    width: 100%;
    height: 100%;
    min-height: 40vw;
  }
}

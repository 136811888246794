@import '../../../../../../../../resources/scss/breakpoints';

.location-search {
    position: relative;
    opacity: 0;
    transition: all 300ms ease-in-out;

    @include mq(lt-phone) {
        text-align: center;
        // width: 300px;
        margin: 100px auto;

        .content-card & {
            margin: 20px auto;
            text-align: left;
            width: 100%;
        }

        .MicrosoftMap {
            text-align: left;
        }
    }

    &.fade-in {
        opacity: 1;
        transition: all 300ms ease-in-out;
    }

    p {
        width: 100%;

        &.error {
            display: none;
            font-size: 12px;
            color: red;

            &.show-error {
                display: block;
            }
        }
    }

    input {
        width: 60%;
        width: 190px;
        font-size: 16px;
        padding: 13px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        height: 45px;
        cursor: pointer;

        &.location-loading {
            cursor: not-allowed;
            pointer-events: none;
            border-radius: 0;
            padding: 0;
        }

        &.location-loading,
        &.location-loading:before,
        &.location-loading:after {
            background: red;
            -webkit-animation: load1 1s infinite ease-in-out;
            animation: load1 1s infinite ease-in-out;
        }

        &.location-loading {
            color: red;
            text-indent: -9999em;
            margin: 0 0 0 26px;
            position: absolute;
            font-size: 9px;
            width: 9px;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
        }

        &.location-loading:before,
        &.location-loading:after {
            position: absolute;
            width: 9px;
            top: 15px;
            content: '';
        }

        &.location-loading:before {
            left: -15px;
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
        }

        &.location-loading:after {
            right: -15px
        }

        @-webkit-keyframes load1 {
            0%,
            80%,
            100% {
                box-shadow: 0 0;
                height: 1.5em;
            }
            40% {
                box-shadow: 0 -1.5em;
                height: 2.5em;
            }
        }

        @keyframes load1 {
            0%,
            80%,
            100% {
                box-shadow: 0 0;
                height: 1.5em;
            }
            40% {
                box-shadow: 0 -1.5em;
                height: 2.5em;
            }
        }
    }

    .full-width {
        width: fit-content;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        @include mq(lt-phablet) {
             display: flex;
             flex-direction: column;
         }

        h2 {
            width: 100%;
            text-align: center;
        }

        > div {
            width: fit-content;
            padding: 0 30px;

            &:first-of-type {
                border-right: 1px SOLID black;

                @include mq(lt-phablet) {
                    border-right: none;
                }
            }

            .use-current {
                border: 0;
                color: white;
                background-color: red;
                margin: 0;
                padding: 0 20px 0 50px;

                @include mq(lt-phablet) {
                    margin: 0 40px 20px;
                }

                &:before {
                    height: 20px;
                    width: 20px;
                    left: 18px;
                    top: 14px;
                    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
                }
            }
        }
    }

    // Map Overrides
    // AutoSuggest Overrides
    .MicrosoftMap {
        z-index: 11;

        .as_container {
            ul {
                padding: 0;

                li {
                    background-color: white;
                    border-bottom: 1px solid lightgray;
                }
            }

            .bingLogoLight {
                display: none;
            }
        }

        .as_container_search {
            left: 0;
            top: 0;
            width: 290px;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .asOuterContainer {
                border: 0px;
            }
        }
    }

    &.location-search--overlay {
        .MicrosoftMap .as_container_search {

            @include mq(lt-phablet) {
                left: calc(50% - 155px);
                top: calc(50% - 43px);
            }

            a {
                border-bottom: 0;
            }
        }
    }


    // Full-Width Mode Dealer Search
    &[data-full-width='true'] {
        .MicrosoftMap {
            left: calc(50% - 15px);
            top: 102px;
        }
    }

    // Content Card Embedded Dealer Search
    .content-card & {
        .MicrosoftMap {
            left: 0;
            top: 0;

            .as_container_search {
                .asOuterContainer {
                    ul {
                        li {
                            border: 1px SOLID lightgray;
                        }
                    }
                }
            }
        }
    }

    .store-locator .content-card & {
        .MicrosoftMap {
            left: 0;
            top: -108px;
            width: 350px;

            .as_container_search {
                width: 350px;
                height: 168px;

                .asOuterContainer {
                    height: 168px;
                    border: 0;
                    box-shadow: none;

                    ul {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        height: 168px;

                        li {
                            background-color: white;
                            border-top: 0;
                            border-bottom: 1px solid lightgray;
                            border-left: 1px solid lightgray;
                            border-right: 1px solid lightgray;

                            &:first-of-type {
                                border-top: 1px solid lightgray;
                            }
                        }
                    }
                }
            }
        }
    }
}
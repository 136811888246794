@import '../../../../../../../resources/scss/breakpoints';

.store-locator {
    opacity: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .store-cards {

        @include mq(lt-tablet) {

            bottom: 0;
            padding-top: 5px;
            order: 1;
            //z-index: 101; // Higher value because of the BING Map
            overflow: scroll;

            &-list-view {

                &.store-cards {
                    top: 5px;
                    order: 1;
                }

                .cmp-store-cards {

                    height: calc(100vh - 180px);
                    width: 90%;
                    margin: 0 auto;

                    > div:not(.basket-transfer-filter) {
                       flex-direction: column;

                       .store-card {
                           max-height: 265px;
                       }
                   }

                }

                + .map .list-view-cta {
                    bottom: 50px;
                    right: 20px;
                    z-index: 101;
                }

            }
        }

    }

    &__location-unknown {
        width: 100%;
        opacity: 1;
        transition: all 300ms ease-in-out;

        &.fade-out {
            opacity: 0;
            transition: all 300ms ease-in-out;
        }

        // hide this; added when location is known
        &.hide {
            display: none;
            max-height: 0px;
            min-height: 0px;
        }

        // when this is not hidden hide all the other locator siblings
        &:not(.hide)~* {
            opacity: 0;
            max-height: 1px;
            min-height: 1px;
        }
    }

    &__cf {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 30px;
        background-color: lightgray;
    }

    &__shop-online {
        z-index: 99;
        width: 100%;
        height: calc(100% - 45px);
        position: absolute;
        left: 0;
        right: 0;
        top: 45px;
        background-color: white;
        opacity: 0;
        transition: all 300ms ease-in-out;
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        padding: 40px 0 0 0;
        transition: all 300ms ease-in-out;
        @include mq(lt-tablet) {
            overflow: scroll;
        }
    }

    table {
        border-collapse: separate;
        border-spacing: 10px;
        width: 550px;
        margin: 0 auto;

        img {
            border: 1px SOLID lightgray;
        }
    }
}


.tire-search-flyout {

    &__tab-content {
        position: relative;
        width: 300px;
        margin: 0 auto 10px;

        input[type=radio] {
            position: absolute;
            left: -99999em;
            top: -99999em;
            opacity: 1;
            z-index: 1;

            + label {
                position: relative;
                cursor: pointer;
                float: left;
                margin-right: -1px;
            }

            &:checked+label {
                z-index: 1;
            }
        }
    }

    &__filters {
        width: 95%;
        margin: 0 auto 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include mq(lt-tablet) {
            justify-content: space-around;
            width: 100%;
        }

        &__location {

            .location-search {
                display: none;
                position: absolute;
                width: 300px;
                box-sizing: content-box;
                padding: 20px 35px 20px 20px;
                right: 0;
                top: 120px;
                background-color: white;
                z-index: 101;
                border: 1px SOLID gray;

                &--overlay {
                    display: block;
                }

                &--unknown {
                    left: 0;
                    top: 70pt;
                    width: 100%;
                    height: 250px;
                    border: 0;
                    box-sizing: border-box;
                }

                .close-overlay {
                    z-index: 201;
                    position: absolute;
                    top: -5px;
                    right: 10px;
                    font-size: 32px;
                    cursor: pointer;

                    &::after {
                        content: '×';
                    }
                }

                @include mq(lt-tablet) {
                    right: 0;
                    left: initial;
                }

                @include mq(lt-phone) {
                    box-shadow: none;
                    box-sizing: border-box;
                    border: 0;
                    height: 100vh;
                    left: 0;
                    right: initial;
                    top: 0;
                    width: 100vw;
                    z-index: 201;
                    position: absolute;
                    margin: 0;
                    padding-top: 50%;
                }
            }

            &__select {
                display: none;
                position: absolute;
                z-index: 101;
                background-color: white;
                width: 340px;
                height: auto;
                left: 30px;
                top: 135px;
                padding: 20px;
                box-sizing: border-box;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
                list-style: none;
                margin: 0;

                p {
                    width: 100%;
                }

                input,
                button {
                    width: 50%;
                }

                input {
                    font-size: 16px;
                    padding: 10px;
                    height: 45px;
                    box-sizing: border-box;
                }

                button {
                    border: 0;
                    height: 45px;
                }

                .close-overlay {
                    z-index: 201;
                    position: absolute;
                    top: -5px;
                    right: 10px;
                    font-size: 32px;
                    cursor: pointer;

                    &::after {
                        content: '×';
                    }
                }

            }

            &__select--active {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }

    &__retailers.store-locator__cf {
        position: absolute;
        top: 80px;
        width: 100%;
        height: 100%;
        z-index: 101;
        background-color: white;
        justify-content: flex-start;
        opacity: 0;
        overflow: scroll;
        padding-bottom: 100px;
        transition: all 300ms ease-in-out;

        &.fade-in {
            opacity: 1;
            transition: all 300ms ease-in-out;
        }


        h3,
        p {
            display: none;
        }

        table {
            width: 300px;

            tr {
                width: 300px;
                display: flex;
                flex-direction: column;

                td {
                    margin: 10px 0;
                    width: 300px;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    border: 1px solid lightgray;
                }
            }
        }
    }
}


// too generic class name for here
.fade-in {
    opacity: 1;
    transition: all 300ms ease-in-out;
}
@import '../../../../../../../resources/scss/breakpoints.scss';

.custom-dropdown {
    list-style: none;
    position: relative;
    //border: 1px solid #A2A4B2;
    //border-bottom-left-radius: 2px;
    //border-bottom-right-radius: 2px;
    width: auto;
    height: 40px;
    cursor: pointer;
    margin: 10px auto;
    padding: 0;

    a {
        text-decoration: none;
        color: darkgray;
        font-size: 14px;
    }

    &__label {
        position: absolute;
        top: -9px;
        left: 15px;
        background: #fff;
        color: #444;
        font-size: .8em;
        font-family: Lato, sans-serif;
    }

    &__arrow {
        position: absolute;
        right: 70px;
        top: 50%;
        transition: transform .2s linear;

        &.expanded {
          transform: rotate(-180deg);
        }

        .footer & {
            fill: white;
            right: -20px;
            top: 16px;
        }

        *[data-icons-only='true'] & {
            right: -10px;
            top: 37%;
        }
    }

    &__list {
        background-color: white;
        width: 100%;
        position: absolute;
        left: 0;
        top: 23px;
        transition: opacity .1s cubic-bezier(0, 0, 0.38, 0.9), max-height .5s cubic-bezier(0, 0, 0.38, 0.9);
        max-height: 0;
        overflow: hidden;
        opacity: 0;

        .mobile-nav-footer & {
            top: 0;
        }

        .footer & {
            bottom: 55px;
            top: auto;
            left: 0;
            width: 200px;
            padding: 0;

            @include mq(lt-tablet) {
                left: calc(50% - 90px);
            }
        }
    }

    &__list-container {
        position: relative;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li p {
            margin: 1px 0 0 0;
        }
    }

    &__list-item {
        font-family: Lato, sans-serif;
        padding: 5px 0;
        transition: background-color .1s linear, color .1s linear;
        list-style-position: inside;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        *[data-icons-only='true'] & {
            padding: 10px;
        }

        &:hover,
        &:focus {
            background-color: #00C2FF;
            color: white;
        }


        img {
            height: 12px;
            width: 18px;
            margin-right: 5px;
        }

        > div {
            a {
                *[data-icons-only='true'] & {
                    background-color: transparent;
                    height: fit-content;
                    width: fit-content;
                    padding: 0;
                    min-height: auto;
                    color: black;
                }
            }
        }

        .footer & {
            padding: 6px 10px;
            margin-bottom: 0;
        }
    }
}

.custom-dropdown__selected {
    //font-family: Helvetica Neue, Helvetica, sans-serif;
    color: #444444;
    padding: 10px 0px;
    list-style-position: inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
        outline: 1px solid #00C2FF;
    }

    > div {
        margin: 1px 0 0 0;
        max-width: 95%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            height: 12px;
            width: 18px;
            margin-right: 5px;
        }
    }
}

.open {
    opacity: 1;
    overflow: auto;
    max-height: 15rem;
    width: fit-content;
    min-width: max-content;

    .mobile-nav-footer & {
        width: 100%;
    }
}
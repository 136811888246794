@import '../../../../../../../resources/scss/breakpoints.scss';

.vehicle-selector-fitment {
	display: flex;
	flex: 1 100%;
	flex-flow: column nowrap; 
	
	&__selects {
		display: flex; 
		flex: 1 100%;
		flex-flow: row nowrap; 
		padding: 0;
		margin: 0; 
		border-top: 1px solid;
		border-bottom: 1px solid; 
		
		@include mq(lt-tablet) {
			flex-flow: column nowrap; 
			border: 0; 
		}
	}
	
	&__select {
		display: flex; 
		flex: 1 25%; 
		flex-flow: column nowrap;
		border-right: 1px solid; 
		padding: 10px;
		
		&:last-child {
			border-right: 0;
		}

		.fitment__message{
			display: none;
			text-align: center;
			font-size: 24px;
			padding: 20px 0px 25px 5px;
		}
		
		@include mq(lt-tablet) {
			border: 0;  

			.fitment__message{
				display: none;
			}

			&.active{
				.fitment__message{
					display: block;
				}
			}

			&:not(.active){
				.select__heading{
					display: block;
				}

				.select__heading:not(.active){
					display: none;
				}
			}
		} 

		@include mq(lt-phone) {
			&.active{
				.fitment__message{
					margin-top: 15%;
				}
			}
		}

		
		
		.select__options {
			overflow-y: scroll;
			height: 50vh;

			@include mq(lt-tablet) {
				height: 32vh; //24 37


				&.minimize-options{
					height: 5.5vh; 
					overflow: auto;
				}
			}

			@include mq(lt-phablet) {
				&.minimize-options{
					height: 5.5vh; 
					overflow: auto;
				}
			}

			@include mq(lt-phone) {
				&.minimize-options{
					height: 5.5vh; 
					overflow: auto;
				}
			}

			> span {
				display: block; 
				padding: 10px 14px; 
				
				&:last-child {
					margin-bottom: 0;
				}
				
				&.active {
					/* generic color */
					background-color: #ccc; 
				}

				@include mq(lt-tablet) {
					&.hide{
						display: none;
					}
				}
			}
		}
	}
	
	&__display {
		display: flex; 
		padding: 10px; 

		@include mq(lt-tablet) {
			position: sticky;
			margin-bottom: 0px !important;
			background-color: lightgrey;
			min-height: 38px;
		}
		@include mq(lt-phablet) {
			position: fixed;
			bottom: 0px;
			width: 100%;
			min-height: 38px;
		}
	}
}
.vehicle-app-wrap {
  a {
    cursor: pointer;
    &.make-model {
      font-size: .65em;
      max-width: 150px;
      //margin-top: -5px;
      text-align: center;
      text-transform: uppercase;
      white-space: normal;
    }
  }
  &.two-col {
    display: grid;
    grid-template-columns: auto auto;
    i {
      color: red;
    }
  }
}
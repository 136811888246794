@import '../../../../../../../resources/scss/breakpoints.scss';

.will-it-fit {
	text-align: center;

	> div {
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		> span {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.icon-consumer-fits,
	.icon-consumer-notsure,
	.icon-consumer-doesnotfit {
		display: flex;
		margin-right: 7px;
	    color: #666;
	}
}

.will-it-fit--fit {
	.will-it-fit__fit {
		 display: flex;

		 &-microcopy {
			 order: 3 !important;
			 font-style: italic;
			 text-transform: none;
			 font-size: 10px;
			 margin: 20px 0 0 0;
		 }

		 > a[href="#locate-tire"] {
			 width: 90%;
		 }
	}
}

.will-it-fit--notfit {
	.will-it-fit__notfit {
		 display: flex;
	}
}

.will-it-fit--notsure {
	.will-it-fit__notsure {
		display: flex;
	}
}
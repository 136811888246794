$bp-rules: (
    'phone',
    'phablet',
    'tablet',
    'desktop',
    'lg-desktop',
    'max',
    'lt-phone',
    'lt-phablet',
    'lt-tablet',
    'lt-desktop',
    'lt-max',
    'phablet-only',
    'tablet-only',
    'desktop-only',
    'phablet-to-desktop'
);

$bp-phone: 480px;
$bp-phablet: 768px;
$bp-tablet: 1024px;
$bp-desktop: 1440px;
$bp-max: 1800px;

@mixin mq($rule) {
    @if index($bp-rules, $rule) {
        @if $rule == phone {
            @media only screen and (min-width: ($bp-phone)) { @content; }
        }

        @else if $rule == phablet {
            @media only screen and (min-width: ($bp-phablet)) { @content; }
        }

        @else if $rule == tablet {
            @media only screen and (min-width: ($bp-tablet)) { @content; }
        }

        @else if $rule == desktop {
            @media only screen and (min-width: ($bp-desktop)) { @content; }
        }

        @else if $rule == max {
            @media only screen and (min-width: ($bp-max)) { @content; }
        }

        @else if $rule == lt-phone {
            @media only screen and (max-width: ($bp-phone)) { @content; }
        }

        @else if $rule == lt-phablet {
            @media only screen and (max-width: ($bp-phablet - 1)) { @content; }
        }

        @else if $rule == lt-tablet {
            @media only screen and (max-width: ($bp-tablet - 1)) { @content; }
        }

        @else if $rule == lt-desktop {
            @media only screen and (max-width: ($bp-desktop - 1)) { @content; }
        }

        @else if $rule == lt-max {
            @media only screen and (max-width: ($bp-max - 1)) { @content; }
        }

        @else if $rule == phablet-only {
            @media only screen and (min-width: ($bp-phablet)) and (max-width: ($bp-tablet - 1)) { @content; }
        }

        @else if $rule == tablet-only {
            @media only screen and (min-width: ($bp-tablet)) and (max-width: ($bp-desktop - 1)) { @content; }
        }

        @else if $rule == desktop-only {
            @media only screen and (min-width: ($bp-desktop)) and (max-width: ($bp-max - 1)) { @content; }
        }

        @else if $rule == phablet-to-desktop {
            @media only screen and (min-width: ($bp-phablet)) and (max-width: ($bp-desktop - 1)) { @content; }
        }
    }

    @else {
        @warn 'Unknown `#{$rule}` in $bp-rules when calling mixin media.';
    }
}

.stars {
    width: 250px;
    height: 50px;
    position: relative;

    .tire-rating-medium & {
        width: 125px;
        height: 25px;
    }

    .tire-rating-small & {
        width: 100px;
        height: 20px;
    }

    .tire-rating-extra-small & {
        width: 65px;
        height: 13px;
    }

    &:before { // empty
        content: "";
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTggMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+U3Rhci9lbXB0eTwvdGl0bGU+CiAgICA8ZyBpZD0iU3Rhci9lbXB0eSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTguNTU5NTA4NjUsMS4zMDE5Nzk0OSBMMTAuNTk1NjYxLDYuMTk3NDc2NzYgTDE1Ljg4MDc2MTIsNi42MjExODA4NCBMMTEuODU0MDcyMywxMC4wNzA0Njg2IEwxMy4wODQyOTE2LDE1LjIyNzgyOTQgTDguNTU5NTA4NjUsMTIuNDY0MTA5MiBMNC4wMzQ3MjU3MiwxNS4yMjc4Mjk0IEw1LjI2NDk0NDk5LDEwLjA3MDQ2ODYgTDEuMjM4MjU2MDgsNi42MjExODA4NCBMNi41MjMzNTYzMyw2LjE5NzQ3Njc2IEw4LjU1OTUwODY1LDEuMzAxOTc5NDkgWiIgaWQ9IlN0YXIiIHN0cm9rZT0iI0Y1QzMwMCI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=");
        background-repeat: repeat-x;
        background-size: 50px;
        bottom: 0;
        display: block;
        height: 50px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 250px;

        .tire-rating-medium & {
            background-size: 25px;
            width: 125px;
            height: 25px;
        }

        .tire-rating-small & {
            background-size: 20px;
            width: 100px;
            height: 20px;
        }

        .tire-rating-extra-small & {
            background-size: 13px;
            width: 65px;
            height: 13px;
        }
    }

    > span {
        display: block;
        text-indent: -10000px;
        width: 0;
        height: 50px;
        position: absolute;
        overflow: hidden;
        transition: width 0.3s ease-in-out;

        .tire-rating-medium & {
            height: 25px;
        }

        .tire-rating-small & {
            height: 20px;
        }

        .tire-rating-extra-small & {
            height: 13px;
        }

            &:before { // filled
                content: "";
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTggMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+U3Rhci9maWxsZWQ8L3RpdGxlPgogICAgPGcgaWQ9IlN0YXIvZmlsbGVkIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0iU3RhciIgZmlsbD0iI0Y1QzMwMCIgcG9pbnRzPSI4LjU1OTUwODY1IDEzLjA1IDMuMjY5NDQxMzggMTYuMjgxMTUyOSA0LjcwNzcyOTc2IDEwLjI1MTUxODggMS4xMzY4NjgzOGUtMTMgNi4yMTg4NDcwNSA2LjE3ODk3ODM3IDUuNzIzNDgxMTcgOC41NTk1MDg2NSAxLjEzNjg2ODM4ZS0xMyAxMC45NDAwMzg5IDUuNzIzNDgxMTcgMTcuMTE5MDE3MyA2LjIxODg0NzA1IDEyLjQxMTI4NzUgMTAuMjUxNTE4OCAxMy44NDk1NzU5IDE2LjI4MTE1MjkiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
                background-repeat: repeat-x;
                background-size: 50px;
                bottom: 0;
                display: block;
                height: 50px;
                left: 0;
                position: absolute;
                right: 0;
                text-indent: 10000px;
                top: 0;

                .tire-rating-medium & {
                    background-size: 25px;
                    height: 25px;
                }

                .tire-rating-small & {
                    background-size: 20px;
                    height: 20px;
                }

                .tire-rating-extra-small & {
                    background-size: 13px;
                    height: 13px;
                }
            }
      }
}

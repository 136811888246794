@import '../../../../../../../resources/scss/breakpoints';

.content-card-features {

    .content-card {

        @include mq(lt-phablet) {
            display: flex;
        }

        // override default absolute positioning of image in content card
        // so full image is visible and it's height controls the height of the feature card
        >.image,
        >.cmp-video {
            position: static;

            picture {
                position: static;
            }
        }

        > div.content-card-features__overlay {
            position: absolute;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            width: 100%;
            bottom: 0;
            color: white;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.9) 100%);

            @include mq(lt-phablet) {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                background-image: none;
                color: black;
                padding: 20px 0 0;
            }

            > div {
                width: 25%;
                padding: 4%;

                @include mq(lt-phablet) {
                    height: auto;
                    width: 50%;
                    margin-bottom: 4%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                }

                span {
                    font-size: 1.5em;
                }

                // why are these divs and not elements/classes/BEM????
                div:first-of-type {
                    font-size: 16px;
                    line-height: 1em;
                    margin: 10px 0;
                }

                div:last-of-type {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .background-video {
            width: 100%;
            opacity: 0;
            position: absolute;
            transition: all 300ms;

            &.loaded {
                opacity: 1;
                transition: all 300ms;
            }
        }
    }
}
.accordion {
    ul{
      margin: 0 auto;
      list-style: none outside;
    }

    .cmp-accordion__item-label {
      display: inline-block; //was block
      position: relative;
      cursor: pointer;
    }

    .cmp-accordion__item-icon::after {
      content: '+';
    }

    .cmp-accordion__item-text {
      visibility: hidden;
      opacity: 0;
    }

    .cmp-accordion__item-input {
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1;
      overflow: hidden;
      position: absolute;
      left: -9999px;
    }

    // update checked/open states
    .cmp-accordion__item-input:checked {
      ~ .cmp-accordion__item-text {
        display: block;
      }

      ~ .cmp-accordion__item-label > .cmp-accordion__item-icon::after{
        content: '-';
      }

    }
    
}


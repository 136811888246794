@import '../../../../../../../resources/scss/breakpoints'; // ui.frontend/src/main/webpack/resources/scss/_breakpoints.scss
.reviews {
    .rating-histogram {
        min-width: 300px;
        margin: 30px auto;

        @include mq(lt-tablet) {
            margin: 10px auto 0;
        }

        @include mq(lt-phablet) {
            margin: 10px auto 20px;
            min-width: 90%;
        }

        > .heading {
            @include mq(lt-phablet) {
                text-align: center;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            margin: 0;
            padding: 0;
        }

        .number-of-stars {
            margin-right: 5px;
            width: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            @include mq(lt-phablet) {
                font-size: 16px;
            }
        }

        .count {
            margin-top: 0;
            margin-left: 10px;
            font-size: 18px;

            @include mq(lt-phablet) {
                font-size: 14px;
            }
        }
        .star {
            margin-right: 15px;
            margin-top: 3px;
        }
    }

    .rating-bar {
        background-color: lightgray;
        height: 20px;
        margin-top: 2px;
        width: 320px;

        @include mq(lt-phablet) {
            width: 70%;
        }
    }

    .rating-bar-inner {
        height: 100%;
        background-color: red;
    }
}

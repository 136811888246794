@import "../../../../../../../../resources/scss/breakpoints";

.store-seo-funnel {

  .intro-blurb {
    margin: 40px 0;
  }

  // Stand-alone Location Search Styles
  .location-search {
    // padding: 30px 0;
    height: 15%;
    margin: 30px 0;
    position: relative;
    background-color: whitesmoke;
  }

  // Hide Store Filter when Map is Present
  .store-filter {
    &__tab-content {
      display: none;
    }

    &__filters {
      &__location {
        display: none;
      }
    }
  }

  .store-locator {
    .store-cards {
      opacity: 0;

      &.fade-in {
        opacity: 1;
      }
    }

    .map {
      opacity: 0;

      &.fade-in {
        opacity: 1;
      }
    }
  }

  // Tire Brand/Tire Card 'Carousel'
  .tire-brands {
    margin: 60px 0;

    .in-page-nav--brands {

      .button {
        width: 12%;
        height: 150px;

        @include mq(lt-tablet) {
          width: 20%;
        }

        > * {
          background-position: 0 100%;

          @include mq(lt-tablet) {
            background-position: 0 130%;
          }
        }

      }
    }
  }

  // Content Fragments
  .ssf-cfs {
    padding: 40px 0;
    margin-bottom: 60px;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;

    > div {
      margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 0;
        }

      b {
        text-transform: uppercase;
      }
    }
  }

  // Store Cards on City Pages
  .ssf-storecards {

    .ssf-storecard-wrapper {
      overflow: hidden;
      max-height: 418px;
      transition: max-height 0.3s ease-in-out;

    &-expanded {
      max-height: none !important;
      overflow: visible;
    }
      

      .ssf-storecard-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 30px;
        column-gap: 2em;

        @include mq(lt-tablet) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include mq(lt-phablet) {
          grid-template-columns: repeat(2, 1fr);
        }

        @include mq(lt-phone) {
          grid-template-columns: repeat(1, 1fr);
        }

        .ssf-storecard {
          border: 1px solid black;
          padding: 10px 20px;
          min-height: 415px;
          max-height: 415px;
          display: flex;
          flex-direction: column;

          p {
            font-size: 0.8125em;
            line-height: 20px;
          }

          &__phone,
          &__location {
            position: relative;
            margin: 15px 0 0 -15px;
            padding: 0 20px 10px 35px;
          }

          &__name {
            font-size: 1em;
            letter-spacing: 0.5px;
            line-height: 19px;
            text-transform: uppercase;
          }

          &__hours {
            margin: auto 0;

            &--list {
              font-size: 0.8125em;
              line-height: 20px;
              padding: 0;
              list-style-type: none;
              margin-top: -5px;
            }
          }

          .button {
            margin-top: auto;
          }
        }
      }
    }

    .show-more-button {
      cursor: pointer;
      a {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      .chevron-down {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        svg path {
          stroke: black;
        }
      }
    }

    .show-more-hidden {
      display: none;
    }
  }

  // Address Pages
  .ssf-details {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include mq(lt-tablet) {
      flex-direction: column;
    }

    &-info {
      width: 390px;

      @include mq(lt-tablet) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      &__header {
        padding: 20px;

        @include mq(lt-tablet) {
          width: 100%;
          padding: 20px 0 0;
        }

        &-see-all {
          display: block;
          position: relative;
          padding-left: 26px;
          margin-bottom: 15px;

          &:before,
          &:after {
            content: "";
            position: absolute;
            display: block;
            border: 7px solid transparent;
          }

          &:before {
            top: 2px;
            left: 0;
            border-right-color: black
          }

          &:after {
            left: 3px;
            top: 2px;
            border-right-color: white;
          }
        }

        h2 {
          line-height: 1.1em;
        }

        a.button {
          margin-top: 20px;
          padding: 0 40px;
        }

      }

      &__wrapper {

        @include mq(lt-tablet) {
          width: 50%;
        }

        @include mq(lt-phablet) {
          width: 100%;
        }

        > div {
          border-top: 1px SOLID grey;
          font-size: 14px;
          line-height: 18px;

          &:nth-of-type(1){
            border-top: 0;
          }

          a :not(.button) {
            text-decoration: underline;
          }

        }

      }

      &__hours {

        @include mq(lt-tablet) {
          width: 50%;
        }

        @include mq(lt-phablet) {
          width: 100%;
        }

        ul {
          padding: 0;
          margin: 0;
          width: 80%;

          @include mq(lt-tablet) {
            width: 100%;
          }

          li {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            font-size: 14px;
            line-height: 18px;

            div {
              width: 35%;
              text-align: left;

              &:nth-of-type(2) {
                width: 60%;
              }

              span {
                text-transform: uppercase;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }

    .map {

      .list-view-cta {
        display: none;
      }

    }
  }
}

@import '../../../../../../../../resources/scss/breakpoints';

.tire-filter-right {

    .tire-card-grid {
        display: grid;
        grid-template-columns: minmax(auto, 252px) minmax(auto, 252px) minmax(auto, 252px);
        grid-gap: 2em;
        align-items: start;
        justify-content: center;
        transition: 500ms ease-out;
        opacity: 1;

        @include mq(lt-desktop) {
            grid-template-columns: repeat(auto-fit, 252px);
        }

        /*
        @media only screen and (max-width: 1260px) {

            grid-template-columns: minmax(auto, 252px) minmax(auto, 252px);

        }

        @include mq(lt-phablet) {
            grid-template-columns: repeat(auto-fit, 252px);

        }
        */

        &--fade-out {
            transition: 500ms ease-out;
            opacity: 0;
        }

        &.single-size {

            .cmp-tire-card-search {

                &.front,
                &.rear {

                    &:before {
                        display: none;
                    }

                }
            }
        }

        &.highest-rated-hide {

            .cmp-tire-card-search {

                &.highest-rated {

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.tire-comparison-tray {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: fixed;
    left: 0;
    bottom: -200px;
    background-color: white;
    height: 200px;
    width: 100%;
    transition: 300ms ease-out;
    border-top: 1px solid #d9d9d9;
    z-index: 1;

    &--active {
        bottom: 0;
        transition: 300ms ease-out;
    }
}
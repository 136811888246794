.cmp-share {

    button {
        cursor: pointer;
        border: none;
        background: none;

        span {
            display: block;
            height: 0;
            overflow: hidden;
            position: absolute;
            text-indent: -9999em;
        }

        svg {
            height: 24px;
            width: 24px;
        }
    }
}

.cmp-share-modal {

    display: none;
    visibility: none;
    position: relative;

    &.active {
        display: flex;
        visibility: visible;
        margin: 25px;
    }

    .modal-close {
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
    }

}

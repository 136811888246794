/*
	CCPA Cookie Consent Experience Fragment
*/
.cmp-experiencefragment--cookie-consent {
	.xf-content-height {
		min-height: 0; 
		margin: 0; 
	} 	
}

.cookie-consent-container {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999999; //make sure it covers anything else 1050;
    width: 100%;
    padding: 20px 15px;
    font-size: 16px;
    text-align: center;
    background-color: rgba(white, .9); 
    border-top: 1px solid #0f0f0f;
    backdrop-filter: blur(8px);

    .cc-close__btn {
        display: block;
        position: relative;
        width: 20px;
        height: 20px;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjAiIGhlaWdodD0iMjAiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIHRyYW5zZm9ybT0iIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtZGFzaGFycmF5PSIiIHN0cm9rZS1kYXNob2Zmc2V0PSIwIiBmb250LWZhbWlseT0ibm9uZSIgZm9udC13ZWlnaHQ9Im5vbmUiIGZvbnQtc2l6ZT0ibm9uZSIgdGV4dC1hbmNob3I9Im5vbmUiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbm9ybWFsIj48cGF0aCBkPSJNMCwxNzJ2LTE3MmgxNzJ2MTcyeiIgZmlsbD0ibm9uZSI+PC9wYXRoPjxnIGZpbGw9IiMwMDAwMDAiPjxwYXRoIGQ9Ik0zMy43MzM3MiwyMy41OTk2MWwtMTAuMTM0MTEsMTAuMTM0MTFsNTIuMjY2MjgsNTIuMjY2MjhsLTUyLjI2NjI4LDUyLjI2NjI4bDEwLjEzNDExLDEwLjEzNDExbDUyLjI2NjI4LC01Mi4yNjYyOGw1Mi4yNjYyOCw1Mi4yNjYyOGwxMC4xMzQxMSwtMTAuMTM0MTFsLTUyLjI2NjI4LC01Mi4yNjYyOGw1Mi4yNjYyOCwtNTIuMjY2MjhsLTEwLjEzNDExLC0xMC4xMzQxMWwtNTIuMjY2MjgsNTIuMjY2Mjh6Ij48L3BhdGg+PC9nPjxwYXRoIGQ9IiIgZmlsbD0ibm9uZSI+PC9wYXRoPjwvZz48L2c+PC9zdmc+')
            50% 50% no-repeat;
        background-size: 100%;
        cursor: pointer;
        float: right;
    }

    .cookie-consent-wrapper {
        outline: none;

        .cc-message-section {
            margin: 10px 0;
            outline: none;
        }

        .cc-message__text {
            vertical-align: middle;
            text-align: left;
            max-width: 1024px;
        }

        .cc-submit__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            margin: 15px auto 0;
            border: none;
            box-sizing: border-box;
            background-color: #666;
            color: white;
            font-size: 14px;
            line-height: 1.2em;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            cursor: pointer;
            transition: background-color 0.3s;
            
            &:hover {
                background-color: darken(#999, 10%);
            }
        }
    }
}

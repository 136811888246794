@import '../../../../../../../resources/scss/breakpoints.scss';

.explore-tires {
	display: block;
	
	/*&__tabs {

		.cmp-tabs__tablist {
			display: flex;
			flex-flow: row nowrap; 
			align-items: center; 
			justify-content: center; 
		}

		.cmp-tabs__tab {
			display: flex; 
		}

	}*/
	
}
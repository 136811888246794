@import '../../../../../../../resources/scss/breakpoints.scss';

.chat-widget {

    @include mq(lt-phablet) {
        display: none;
    }

    &__cta {
        // BATO2020-1072 :: removing z indexes to test necessity
        ////////////////////z-index: 11;
        z-index: 1001; // makes for nicer transition
        position: fixed;
        bottom: 20px; //20 124
        right: 25px;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        background-color: red;
        cursor: pointer;
        font-family: 'consumer-icons' !important;
        animation-name: slideInCTA;
        animation-duration: 300ms;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(1, 1.1, 0, 1);
        animation-fill-mode: forwards;

        &:before {
            content: "\e912";
            color: white;
            font-size: 32px;
            position: absolute;
            left: 18px;
            top: 18px;
        }

        &.open {
            animation-name: slideOutCTA;
            animation-duration: 300ms;
            animation-iteration-count: 1;
            animation-timing-function: cubic-bezier(1, 1.1, 0, 1);
            animation-fill-mode: forwards;
        }
    }

    &__text {
        clip: rect(0 0 0 0);
        overflow: hidden;
        position: absolute;
        height: 1px;
        width: 1px;
    }

    &__buttons {
        // BATO2020-1072 :: removing z indexes to test necessity
        ////////////////////z-index: 10;
        z-index: 1002; // makes for nicer transition
        font-family: sans-serif;
        position: fixed;
        bottom: -320px;
        right: 20px;
        width: 250px;
        height: 320px;
        padding: 25px 25px 0;
        border: 1px SOLID lightgray;
        background-color: white;
        animation-name: slideOutButtons;
        animation-duration: 300ms;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(1, 1.1, 0, 1);
        animation-fill-mode: forwards;

        &.open {
            animation-name: slideInButtons;
            animation-duration: 300ms;
            animation-iteration-count: 1;
            animation-timing-function: cubic-bezier(1, 1.1, 0, 1);
            animation-fill-mode: forwards;
            max-height: 320px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-content: space-between;

            .button{
                display: inline-flex;
            }

            .close-overlay {
                display: block;
            }
        }

        .close-overlay {
            // BATO2020-1072 :: removing z indexes to test necessity
            ////////////////////z-index: 201;
            position: absolute;
            top: 0px;
            right: 15px;
            font-size: 32px;
            cursor: pointer;
            display: none;

            &::after {
                content: '×';
                color: lightgray;
            }
        }

        .h3 {
            text-align: center;
            width: 120px;
            margin: 0 auto 15px;
            line-height: 1.25em;
        }

        .button {
            cursor: pointer;
            width: 100%;
            margin-bottom: 10px;
            display: none;

            span {
                font-size: 1.2em;
                margin-right: 10px;
            }
        }
    }

    @keyframes slideInCTA {
        from { transform: translateY(120px); }
        to { transform: translateY(-20px); }
    }

    @keyframes slideOutCTA {
        from { transform: translateY(-20px); }
        to { transform: translateY(120px); }
    }

    @keyframes slideInButtons {
        from { transform: translateY(0px); }
        to { transform: translateY(-320px); }
    }

    @keyframes slideOutButtons {
        from { transform: translateY(-320px); }
        to { transform: translateY(0px); }
    }
}
